import { ReactComponent as LeftStoryLine } from "../assets/left.svg";
import "../Check_Page/Check_Page.css";
import "../header/Header_2.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import Web3 from "web3";
import "../Mint_Page/Mint_Page.css"
import { getProof } from "../merkle/merkleTree";
import abiData from "./abi.json";
//import { id } from "ethers/lib/utils";

var ad_short;
var isConnected = false;
var isConnectedAndWhitelisted = false;
var isAmountAvailable = false;
var isRecentlyCompleted = false;
var availablemint = 5;
var interv2_Bar;
var contractAD = '0xc6D12b8fC4Eedea0163456697E81c8ae84Bb0531';
function Mint_Page() {
  //document.getElementById("root").style.display = 'none'; 
  //document.getElementById("myVideo1").style.display = 'none';  
  
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    console.log(data.proof);
    clearInterval(interv2_Bar);
    let cost = data.cost;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = Web3.utils.toWei((cost * mintAmount).toString());
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    document.getElementById('mintpage_mint_waiting').style.display = 'none';
    document.getElementById("mintpage_processing").style.display = "block";
    document.getElementById("mintpage_mintbtn").style.display = 'none';
    document.getElementById("mintpage_mintbtn_disabled").style.display = 'flex';
    document.getElementById('mintpage_upbtn').style.pointerEvents = 'none';
    document.getElementById('mintpage_downbtn').style.pointerEvents = 'none';
    blockchain.smartContract.methods
      [data.publicSale ? 'publicSaleMint' : 'moonlistMint'](mintAmount, ...(data.publicSale ? [] : [data.proof]))
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setMintFail();
        //setMintComplete();

        //interv2_Bar = setInterval(readAllMinted , 10000);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        //interv2_Bar = setInterval(readAllMinted , 10000);
        //setMintFail();
        setMintComplete();

        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getMaxNFTs = () => {
    //clearInterval(interv2_Bar);
    blockchain.smartContract.methods.numberMinted(blockchain.account).call()
    .then((numberMinted) => {
      //interv2_Bar = setInterval(readAllMinted , 10000);
      availablemint = 5 - numberMinted;
      
      if(availablemint > 0){
        document.getElementById('mintpage_mint_waiting').style.display = 'flex';
        document.getElementById('mintpage_text_whitelisted').style.display = 'none';
        document.getElementById('mintpage_ready').style.fontSize = document.getElementById('mintpage_sub1').offsetWidth/12 + 'px';
        document.getElementById('mintpage_ready').innerHTML = availablemint;
        isAmountAvailable = true;
        setMintAmount(availablemint);
      } else {
        if(availablemint < 0) {
          availablemint = 0;
        }
        document.getElementById('mintpage_full').style.display = 'flex';
        document.getElementById("mintpage_mintbtn_disabled").style.display = 'flex';
        document.getElementById('mintpage_mint_waiting').style.display = 'none';
        document.getElementById('mintpage_text_whitelisted').style.display = 'none';
        document.getElementById("mintpage_mintbtn").style.display = 'none';
        document.getElementById('mintpage_upbtn').style.pointerEvents = 'none';
        document.getElementById('mintpage_downbtn').style.pointerEvents = 'none';
        document.getElementById('mintpage_ready').style.fontSize = document.getElementById('mintpage_sub1').offsetWidth/12 + 'px';
        document.getElementById('mintpage_ready').innerHTML = availablemint;
        isAmountAvailable = false;
        setMintAmount(0);
      }
    });
  };

  const getMaxNFTs_public = () => {
    //clearInterval(interv2_Bar);
    availablemint = 5;
    document.getElementById('mintpage_mint_waiting').style.display = 'flex';
    document.getElementById('mintpage_text_whitelisted').style.display = 'none';
    document.getElementById('mintpage_ready').style.fontSize = document.getElementById('mintpage_sub1').offsetWidth/12 + 'px';
    document.getElementById('mintpage_ready').innerHTML = availablemint;
    isAmountAvailable = true;
    setMintAmount(availablemint);
  };

  const decrementMintAmount = () => {
    if(isConnectedAndWhitelisted){
      if(isAmountAvailable){
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
          newMintAmount = 1;
        }
        document.getElementById('mintpage_ready').innerHTML = newMintAmount;
        setMintAmount(newMintAmount);
      }else{
        mintBTNCliked();
      }
    }
  };

  const incrementMintAmount = () => {
    if(isConnectedAndWhitelisted){
      if(isAmountAvailable){
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > availablemint) {
          newMintAmount = availablemint;
        }
        document.getElementById('mintpage_ready').innerHTML = newMintAmount;
        setMintAmount(newMintAmount);
      }else{
        mintBTNCliked();
      }
    }
  };

  const getData = () => {
    if(CONFIG.CONTRACT_ADDRESS){
      readAllMinted();
    }
    resetUI();
    
    if (blockchain.account) {
      dispatch(fetchData(blockchain.account));
      var proof = getProof(blockchain.account);
      //if (proof.length > 0) {   //for whitelist
      if (true) { //for public
        ad_short = "0" +  blockchain.account.substring(1,8) + "..." + blockchain.account.substring(blockchain.account.length - 4) + "  ";
        document.getElementById('mintpage_address').style.display = 'flex';
        document.getElementById('mintpage_connectwallet_btn').style.display = 'none';
        document.getElementById('mintpage_detail_text').style.display = 'none';
        document.getElementById('mintpage_detail_header').style.display = 'none';
        document.getElementById('mintpage_checking_whitelist').style.display = 'flex';
        isConnectedAndWhitelisted = true;
        showWLSuccess();
        connectedWallet();
      } else {
        showWLFail();
      }
      isConnected = true;
    } 
  };

  function connectedWallet() {
    ad_short = "0" +  blockchain.account.substring(1,8) + "..." + blockchain.account.substring(blockchain.account.length - 4) + "  ";
    document.getElementById('mintpage_address').style.display = 'flex';
    document.getElementById('mintpage_connectwallet_btn').style.display = 'none';
    document.getElementById('mintpage_detail_text').style.display = 'none';
    document.getElementById('mintpage_detail_header').style.display = 'none';
    document.getElementById('mintpage_checking_whitelist').style.display = 'flex';
    isConnectedAndWhitelisted = true;
    showWLSuccess();
    isConnected = true;
  }

  const mintBTNCliked = () => {
    if(isConnectedAndWhitelisted && !isAmountAvailable && !isRecentlyCompleted) {
      getMaxNFTs_public();
    }else if(isConnectedAndWhitelisted && isAmountAvailable && !isRecentlyCompleted) {
      claimNFTs();
    }else if(isConnectedAndWhitelisted && isAmountAvailable && isRecentlyCompleted) {
      isRecentlyCompleted = false;
      resetAll();
    }
  }

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    //interv2_Bar = setInterval(readAllMinted , 10000);
    document.getElementById('mintpage_time').style.display = 'none';
    getConfig();
    MintPage_ChangeFontSize();
    window.addEventListener('resize', MintPage_ChangeFontSize);
    return () => {
      window.removeEventListener('resize', MintPage_ChangeFontSize);
      //clearInterval(interv2_Bar);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    console.log(data);
    if(data){   
      if (data.whitelisted) {
        setFeedback('You have been whitelisted');
      } else {
        setFeedback("You aren't whitelisted");        
      }
    }
  }, [data]);

  useEffect(() => {
    if(CONFIG.CONTRACT_ADDRESS) readAllMinted();
  },[CONFIG]);

  function setMintComplete() {
    readAllMinted();
    document.getElementById('mintpage_mintbtn').style.display = 'flex';
    document.getElementById('mintpage_mintbtn_disabled').style.display = 'none';
    document.getElementById('mintpage_success').style.display = 'block';
    document.getElementById('mintpage_mint_waiting').style.display = 'none';
    document.getElementById("mintpage_processing").style.display = "none";
    document.getElementById("mintpage_ready").style.display = "none";
    document.getElementById("mintpage_opensea").style.display = "flex";
    isRecentlyCompleted = true;
  }
  
  function setMintFail() {
    resetAll();
    document.getElementById('mintpage_fail').style.display = 'flex';
    document.getElementById('mintpage_detail_text').style.display = "none";
    document.getElementById('mintpage_detail_header').style.display = "none";
    document.getElementById('mintpage_connectwallet_btn').style.display = 'none';
    document.getElementById('mintpage_mintbtn').style.display = 'none';
    document.getElementById('mintpage_mint_waiting').style.display = 'none';
    setTimeout(()=>{
      resetAll();
    },3000);
  }
  
  function showWLSuccess() {
    document.getElementById('mintpage_checking_whitelist').style.display = 'none';
    document.getElementById("mintpage_mintbtn").style.display = 'flex';
    document.getElementById("mintpage_mintbtn_disabled").style.display = 'none';
    document.getElementById('mintpage_text_whitelisted').style.display = 'flex';
    document.getElementById('mintpage_text_unwhitelisted').style.display = 'none';
    document.getElementById('mintpage_ready').style.display = 'flex';
    document.getElementById('mintpage_upbtn').style.pointerEvents = 'auto';
    document.getElementById('mintpage_downbtn').style.pointerEvents = 'auto';
  }

  function showWLFail() {
    document.getElementById('mintpage_detail_text').style.display = 'none';
    document.getElementById('mintpage_detail_header').style.display = 'none';
    document.getElementById('mintpage_checking_whitelist').style.display = 'none';
    document.getElementById("mintpage_mintbtn").style.display = 'none';
    document.getElementById("mintpage_mintbtn_disabled").style.display = 'flex';
    document.getElementById('mintpage_text_whitelisted').style.display = 'none';
    document.getElementById('mintpage_text_unwhitelisted').style.display = 'flex';
    document.getElementById('mintpage_ready').style.display = 'none';
    document.getElementById('mintpage_upbtn').style.pointerEvents = 'none';
    document.getElementById('mintpage_downbtn').style.pointerEvents = 'none';
  }
  
  function readAllMinted() {
    const web3 = new Web3(Web3.givenProvider || 'http://localhost:7545');
    var abi = JSON.parse(JSON.stringify(abiData));
    const contract = new web3.eth.Contract(abi , contractAD); 
    contract.methods.totalSupply().call().then((totalMinted)=>{
      var tt = parseInt(totalMinted);
      document.getElementById('minted_amount').innerHTML = tt + '/5500';
      //var percentH = parseInt(100 - (100*(tt)/10000));
      //document.getElementById('mintpage_greenbar_img').style.clipPath = 'inset('+ percentH + '% 0% 0% 0%)'; 
    });
  }

  function resetUI(){
    if(document.getElementById('mintpage_detail_text')) {
      document.getElementById('mintpage_detail_text').style.display = 'flex';
      document.getElementById('mintpage_detail_header').style.display = 'flex';
      document.getElementById('mintpage_mintbtn').style.display = 'none';
      document.getElementById('mintpage_mintbtn_disabled').style.display = 'flex';
      document.getElementById('mintpage_connectwallet_btn').style.display = 'flex';
      isConnected = false;
      isConnectedAndWhitelisted = false;
      isAmountAvailable = false;
      availablemint = 2;
      
      document.getElementById('mintpage_text_whitelisted').style.display = 'none';
      document.getElementById('mintpage_address').style.display = 'none';
      document.getElementById('mintpage_upbtn').style.pointerEvents = 'none';
      document.getElementById('mintpage_downbtn').style.pointerEvents = 'none';
      document.getElementById('mintpage_text_whitelisted').style.display = 'none';
      document.getElementById('mintpage_text_unwhitelisted').style.display = 'none';
      document.getElementById('mintpage_full').style.display = 'none';
      document.getElementById('mintpage_mint_waiting').style.display = 'none';
      document.getElementById('mintpage_time').style.display = 'none';
      document.getElementById("mintpage_opensea").style.display = "none";
      document.getElementById('mintpage_ready').style.display = 'none';
      document.getElementById('mintpage_ready').innerHTML = 'READY!';
      var msw = document.getElementById('mintpage_sub1').offsetWidth;
      document.getElementById('mintpage_ready').style.fontSize = msw/30 + 'px';
      document.getElementById('mintpage_processing').style.display = 'none';
      document.getElementById('mintpage_success').style.display = 'none';
      document.getElementById('mintpage_fail').style.display = 'none';
    }
  }
  function resetAll() {
    //readAllMinted();
    resetUI();
  }
  return (
    <div id='mintpage_maindiv'>
      <div id='checkpage_backhome'>
          <Link to="/" ><LeftStoryLine style={{display:'inline',marginBottom:'4%'}}  /></Link>&nbsp;&nbsp;
          <Link to="/" id="checkpage_backhome_text">BACK HOME</Link>
      </div>
      <div id='mintpage_container'>
        <div id='mintpage_sub1'>
          {/* <div id="gif_div" style={{position:'absolute',width:'32%',top:'54%',left:'-1%'}}>
            <img src="Pro1.jpg" alt="" />
          </div> */}
          <div id='mintpage_detail_header'>
            วิธี mint NFT
          </div>
          <div id='mintpage_ready'>
            READY!
          </div>
          <div id='mintpage_opensea'>
            <center>
              <a target="_blank" href="https://www.opensea.io/account" >คลิกที่นี่เพื่อเข้าดู <br/>OpenSea ของท่าน<br/><img alt="" style={{filter:"brightness(.5)" , width:'50%',height:'auto'}}  src="opensea.svg" /></a>
            </center>
          </div>
          <div id='mintpage_checking_whitelist'>
            Moonlist Checking ...
          </div>
          <div id='mintpage_detail_text'>
            1. กดปุ่ม Connect Wallet ด้านล่างเพื่อเชื่อมกระเป๋า MetaMask. <br/>
            2. ท่านจะสามารถ mint ได้ไม่เกิน 5 ชิ้นต่อครั้ง<br/>&nbsp;ในราคาชิ้นละ 0.15 ETH<br/>
            3. รอจนกระทั่ง transaction เสร็จสมบูณณ์<br/>
            4. เมื่อเสร็จแล้ว ท่านสามารถกดปุ่มไปที่ OpenSea เพื่อดู NFT ที่ท่านซื้อ<br/>
          </div>
          <div id='mintpage_text_whitelisted'>
            <center>
              {/* <span id="mintpage_text_whitelisted_head">ยินดีด้วย! ท่านมีรายชื่อ<br/>ใน Moon List</span><br/><br/>
              ท่านสามารถ mint ได้รวมไม่เกิน 5 ชิ้น<br/>
              ราคา 0.10 ETH ต่อชิ้น <br/>
              กรุณาเตรียม ETH ในกระเป๋าของท่านให้เพียงพอ 
            </center> */}
             <span id="mintpage_text_whitelisted_head">ยินดีต้อนรับ! สู่รอบ Early Bird</span><br/><br/>
              ท่านสามารถ mint ได้ครั้งละไม่เกิน 5 ชิ้น<br/>
              ราคา 0.15 ETH ต่อชิ้น <br/>
              กรุณาเตรียม ETH ในกระเป๋าของท่านให้เพียงพอ 
            </center> 
          </div>
          <div id='mintpage_mint_waiting'>
            <center>
              <br/><br/><br/>
              {/* <img id='mint_wait_gif' src='MINT_Waiting.gif' /> */}
              คุณกำลังเลือก mint จำนวน {mintAmount} ชิ้น
              <br/><span style={{fontSize:'150%',lineHeight:'100%'}}>ราคารวม {(mintAmount*0.15).toFixed(2)} ETH + GAS </span>
              <br/>(ราคา 0.15 ETH ต่อชิ้น)<br/>
            </center>
          </div>
          <div id='mintpage_text_unwhitelisted'>
            <center>
              <br/>
              <span id="mintpage_text_unwhitelisted_head">SORRY!</span><br/>
              <br/>เลขกระเป๋านี้ไม่อยู่ในรายชื่อ MOON LIST<br/>
              กรุณากลับมาอีกครั้งในวัน<br/>
              EARLY BIRD SALE (27 กรกฎาคม 2022)
            </center>
          </div>
          <div id='mintpage_full'>
            <center>
              <span id="mintpage_full_header">OOPS!</span><br/><br/>
              <div id="mintpage_full_text">
                ท่านมี BullMoon NFT ในกระเป๋านี้ครบ 5 ชิ้นแล้ว<br/>
                กรุณารอรอบ EARLY BIRD 
              </div>
            </center>
          </div>
          <div id='mintpage_fail'>
            <center>
              <span id="mintpage_fail_header">OOPS!</span><br/><br/>
              <div id="mintpage_fail_text">
                การ Mint ไม่สำเร็จ<br/>
                กรุณาลองใหม่
              </div>
            </center>
          </div>
          <div id='mintpage_connectwallet_btn' onClick={(e) => {
            e.preventDefault();
            if (typeof window.ethereum !== 'undefined') {
              //connectMetaMask()
              dispatch(connect());
              getData();
              window.ethereum.on('accountsChanged', () => {dispatch(connect()); getData();});
            }else{
              window.location.href = 'https://metamask.app.link/dapp/bullmoon.stock2morrow.com/?connect=2';
            }
          }}>
              
          </div>
          <div id="mintpage_address">
            <center>&nbsp;{ad_short == "" ? null : ad_short }</center>
          </div>
          <div id='mintpage_upbtn' onClick={incrementMintAmount} />  
          <div id='mintpage_downbtn' onClick={decrementMintAmount} />
          <div id='mintpage_mintbtn' onClick={mintBTNCliked}/> 
          <div id='mintpage_mintbtn_disabled'/>
          <div id='mintpage_time' ><center>LIVE IN<br/>72:30<br/>hours</center></div> 
          <div id='minted_amount' style={{display:'none'}} >1/5500</div>
          {/* <div id='mintpage_greenbar2'>
            <img src="greenmintbar2.gif" id='mintpage_greenbar2_img'></img>
        </div> */}
          <div id='mintpage_processing'>
            <center>
              <br/>
              <img src='processing.gif' id='mintpage_processing_img' alt=""></img>
              ... กำลังดำเนินการ ...
            </center>
          </div>
          <div id='mintpage_success'>
            <center>
              <img src='complete.gif' id='complete' style={{width:'30%',height:'auto'}} alt=""></img><br/>
              <img src='urbullmoon.png' id='urmazk'  style={{width:'95%',height:'auto'}} alt=""></img>
            </center>
          </div>
        </div>
      </div>
      <div>
      <img src="how_to_mint.jpg" style={{width:'100%'}} />
      </div>
    </div>
  );
}
export default  Mint_Page;

function MintPage_ChangeFontSize() {
  var msw = document.getElementById('mintpage_sub1').offsetWidth;
  document.getElementById('mintpage_detail_text').style.fontSize = msw/50 + 'px';
  document.getElementById('mintpage_text_whitelisted').style.fontSize = msw/50 + 'px';
  document.getElementById('mintpage_detail_header').style.fontSize = msw/16 + 'px';
  document.getElementById('mintpage_address').style.fontSize = msw/42 + 'px';
  document.getElementById('mintpage_text_whitelisted').style.fontSize = msw/36 + 'px';
  document.getElementById('mintpage_text_whitelisted_head').style.fontSize = msw/21 + 'px';
  document.getElementById('mintpage_text_unwhitelisted').style.fontSize = msw/36 + 'px';
  document.getElementById('mintpage_text_unwhitelisted_head').style.fontSize = msw/16 + 'px';
  document.getElementById('mintpage_full').style.fontSize = msw/36 + 'px';
  document.getElementById('mintpage_fail').style.fontSize = msw/36 + 'px';
  document.getElementById('mintpage_full_header').style.fontSize = msw/16 + 'px';
  document.getElementById('mintpage_fail_header').style.fontSize = msw/16 + 'px';
 document.getElementById('mintpage_mint_waiting').style.fontSize = msw/36 + 'px';
  document.getElementById('mintpage_time').style.fontSize = msw/36 + 'px';
  document.getElementById('mintpage_ready').style.fontSize = msw/14 + 'px';
  document.getElementById('minted_amount').style.fontSize = msw/40 + 'px';
  //document.getElementById('mint_wait_gif').style.width = msw/2.5+'px'
  document.getElementById('mintpage_processing').style.fontSize = msw/40+'px'
  document.getElementById('mintpage_opensea').style.fontSize = msw/45+'px'
  
}