import "./Mazk_Banner4.css";
import * as THREE from 'three';
import React, { Component } from "react"; 
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';

//import { DebugEnvironment } from 'three/examples/jsm/environments/DebugEnvironment.js';
//import { ColorSpaceNode, MeshStandardNodeMaterial } from 'three/examples/jsm/nodes/Nodes.js';

var w , h;
var renderer = new THREE.WebGLRenderer({alpha:true, antialias:true});
var camera;
var clock = new THREE.Clock();
var mixer1;
var obj1;
var previousWidth;

var acdx = 0 , acdy = 0;
var vnmu = true;
var bw , bl, tttt;
var divw1;
var btt;

var fng1 = "test1_6.glb"
var fr = 1;
var offY = 0;
var zw = 50;
var jios = 0;
var scene = new THREE.Scene();
var fov1 = 22;
var topStory1 = 0;
var myRFA;
var loader;
var h1=0;

class Banner1 extends Component {
  componentDidMount() {
  }

  componentWillUnmount() {
  }
  
  render() {
    return (
      <div id='bullmoon_banner4_container'>
        <center>
        <div id='bullmoon_banner4_text'>BULL<font style={{color:'yellow'}}>MOON</font>CLUB TEAM</div>
        <div id='bullmoon_banner4_maindiv'>
          <img className="banner4_img" src='member/member01.jpg?c=3'/>
          <img className="banner4_img" src='member/member02.jpg?c=3'/>
          <img className="banner4_img" src='member/member03.jpg?c=3'/>
          <img className="banner4_img" src='member/member04.jpg?c=3'/>
          <img className="banner4_img" src='member/member05.jpg?c=3'/>
          <img className="banner4_img" src='member/member06.jpg?c=3'/>
          <img className="banner4_img" src='member/member07.jpg?c=3'/>
          <img className="banner4_img" src='member/member08.jpg?c=3'/>
          <img className="banner4_img" src='member/member09.jpg?c=3'/>
          <img className="banner4_img" src='member/member10.jpg?c=3'/>
          <img className="banner4_img" src='member/member20.jpg?c=3'/>
        </div>
        </center>
        <br/><br/><br/><br/><br/><br/>
      </div>
    )
  }
}
export default Banner1;