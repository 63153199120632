import "./Exclusive001.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import videoFile from './video1.mp4';

var myRFA;
var difftime, diffHour, diffHour24, diffMinute, diffSecond, diffDay;
var interv5;
class Exclusive001 extends Component {
  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div style={{ width: '100%', height:"50%",fontFamily: 'prompt', fontSize: '35px', backgroundColor: 'rgb(40,40,40)' }}>
        {/* <div style={{fontSize:'max(2vw,24px)', padding:'30px',width:'100%',height:'auto', display:'flex',justifyContent:'center' ,alignContent:'center', backgroundColor:'black'}}>
            <br/><br/><center><br/><br/>คอร์สแนะนำ NFT เบื้องต้นจากทาง Stock2Morrow</center><br/>
          </div>
          <div style={{width:'100%',height:'auto', display:'flex',justifyContent:'center' ,alignContent:'center', padding:'30px',backgroundColor:'black'}}>
            <a href="https://stock2morrow.com/course/28" ><img src="course1.jpg" style={{width:'min(700px,95vw)',height:'auto'}} /></a><br/><br/>
          </div> */}
        <div style={{ backgroundColor: 'rgb(40,40,40)' }} >
          <div className="video-background">
            <Link to="/">
              <a id="exclusive_back_div">
                &lt; Back Home
              </a>
            </Link>
            <center>

              <video className="v2" playsInline loop muted autoPlay preload="true">
                <source src={videoFile} type="video/mp4" />
              </video>
              <div className="content1">
                <div className="text-white mb-4">หลักสูตรการลงทุน BullMoon Exclusive</div>
                <img src="1.jpg?sd=3" style={{ width: 'min(900px,90vw)', height: 'auto' }} />


                <div className="my-4 mx-auto" style={{ width: 'min(900px, 90vw)' }}>
                  <div className="video-responsive">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/2LYIK1vYIEE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>
                </div>

                <img src="2.jpg?sd=3" style={{ width: 'min(900px,90vw)', height: 'auto' }} />
                <img src="3.jpg?sd=3" style={{ width: 'min(900px,90vw)', height: 'auto' }} />
                <img src="4.jpg?sd=3" style={{ width: 'min(900px,90vw)', height: 'auto' }} />
                <img src="5.jpg?sd=3" style={{ width: 'min(900px,90vw)', height: 'auto' }} />
                <img src="6.jpg?sd=3" style={{ width: 'min(900px,90vw)', height: 'auto' }} />
                <img src="7.jpg?sd=3" style={{ width: 'min(900px,90vw)', height: 'auto' }} />
                <img src="8.jpg?sd=3" style={{ width: 'min(900px,90vw)', height: 'auto' }} />
                <div style={{minHeight:"50px",height:"50px"}}>

                </div>
                <br />
                <div style={{position:"fixed",bottom:"0px",width:"100%",display:"flex",flexWrap:"wrap", alignItems:"center",justifyContent:"center",backdropFilter:"blur(10px)", backgroundColor:"rgba(255,255,255,.1)"}}>
                  <button className="button_2" style={{ fontSize: "28px", width: "370px" }}>
                    <a href='https://docs.google.com/forms/d/e/1FAIpQLSdjSx4tFAy5r_CPmaYAeMnIt6ghmTA9e4_h2j7J6BhEdVFznw/viewform' target='_blank'>สมัครหลักสูตร</a>
                  </button>
                </div>
              </div>
            </center>
          </div>
        </div>
      </div>
    )
  }
}
export default Exclusive001;