const executives = [
  {
    name: 'ประกาศิต ทิตาราม',
    role: 'นักลงทุนและนักธุรกิจ<br/>CEO ทิตารามกรุ๊ป',
    image: '/speakers/e2.png'
  },
  {
    name: 'วิชัย ทองแตง',
    role: 'นักธุรกิจและนักลงทุน<br/>แถวหน้าของประเทศไทย',
    image: '/speakers/e1.png'
  },
  {
    name: 'ปิยพันธ์ วงศ์ยะรา',
    role: 'CEO<br/>2morrow group',
    image: '/speakers/e3.png'
  }
]

const directors = [
  {
    name: 'นิติพงษ์ หิรัญพงษ์',
    role: 'Fulltime Trader',
    image: '/speakers/d1.png'
  },
  {
    name: 'อัญพัชร์ ทรัพย์ยืนยง',
    role: 'ผู้เชี่ยวชาญด้านการลงทุนอสังหาฯ',
    image: '/speakers/d2.png'
  },
  {
    name: 'กัณฑ์ คลอวุฒินันท์',
    role: 'นักลงทุน สาย blockchain',
    image: '/speakers/d3.png'
  },
  {
    name: 'พรชัย บุญญาทานวงศ์',
    role: 'NFT Collector & Crypto Specialist',
    image: '/speakers/d4.png'
  }
]

const stockSpeakers = [
  {
    id: 1,
    name: 'วิเชฐ ต้นติวานิช',
    role: 'ที่ปรึกษาคณะกรรมการ<br/>สถาบันวิทยาการตลาดทุน (วตท.)',
    image: '/speakers/stock1.png'
  },
  {
    id: 2,
    name: 'กวี ชูกิจเกษม',
    role: 'Head of Research and Content<br/>หลักทรัพย์ พาย จำกัด (มหาชน)',
    image: '/speakers/stock12.png'
  },
  {
    id: 3,
    name: 'ภาววิทย์ กลิ่นประทุม',
    role: 'นักลงทุนหุ้น นักเขียน วิทยากร',
    image: '/speakers/stock3.png'
  },
  {
    id: 4,
    name: 'โอฬาร ภัทรกอบกิตติ์',
    role: 'แชมป์แฟนพันธ์แท้ตลาดหุ้นไทยคนแรก',
    image: '/speakers/stock4.png'
  },
  {
    id: 5,
    name: 'ชลเดช เขมะรัตนา',
    role: 'President of Thai Fintech Association, CEO Webull',
    image: '/speakers/stock5.png'
  },
  {
    id: 6,
    name: 'เอิญ สุริยะฉาย',
    role: 'เจ้าของเพจ Mr.LikeStock',
    image: '/speakers/stock6.png'
  },
  {
    id: 7,
    name: 'ชยนนท์ รักกาญจนันท์',
    role: 'Head Coach & Co-founder FINNOMENA',
    image: '/speakers/stock7.png'
  },
  {
    id: 8,
    name: 'ทิวา ชินธาดาพงศ์',
    role: 'เซียนหุ้นสาย VI ชื่อดังของไทย',
    image: '/speakers/stock2.png'
  },
  // {
  //   id: 8,
  //   name: 'แป๋ม ลลิตภัทร ธรณวิกรัย',
  //   role: 'ประธานเจ้าหน้าที่บริหาร<br/>บริษัทหลักทรัพย์ ไทยพาณิชย์ จูเลียส แบร์',
  //   image: '/speakers/stock8.png'
  // },
  {
    id: 9,
    name: 'มานิตย์ ศรายุทธิกรณ์',
    role: 'เทรดเดอร์และนักลงทุนอิสระ',
    image: '/speakers/stock9.png'
  },
  {
    id: 10,
    name: 'ธนาเดช โสมบุตร',
    role: 'Fulltime trader',
    image: '/speakers/stock10.png'
  },
  {
    id: 11,
    name: 'ปุณยวีร์ จันทรขจร',
    role: 'CEO<br/>Quantum Wealth',
    image: '/speakers/stock11.png'
  },
  {
    id: 12,
    name: 'พจน์ หะริณสุต',
    role: 'ประธานเจ้าหน้าที่บริหาร บลจ.วรรณ',
    image: '/speakers/stock13.png'
  }
]

const realEstateSpeakers = [
  {
    id: 1,
    name: 'พีระพงศ์ จรูญเอก',
    role: 'CEO<br/>Origin Property',
    image: '/speakers/real-estate1.png'
  },
  {
    id: 2,
    name: 'แพรรินทร์ เรืองปัญญาวุฒิ',
    role: 'เจ้าหญิงวงการอสังหาฯ<br/>จากนิตยสารแพรว',
    image: '/speakers/real-estate2.png'
  },
  {
    id: 3,
    name: 'สุธาทร สุทธิสนธิ์',
    role: 'CEO<br/>Prop2morrow',
    image: '/speakers/real-estate3.png'
  },
  {
    id: 4,
    name: 'โอภาส ถิรปัญญาเลิศ',
    role: 'CEO<br/>Prop2morrow',
    image: '/speakers/real-estate4.png'
  },
  {
    id: 5,
    name: 'ศิรประภา รักษ์สุจริต',
    role: 'เจ้าของเพจอสังหาเรื่องจิ๊บๆ<br/>CEO Wealthiness Estate',
    image: '/speakers/real-estate5.png'
  },
  {
    id: 6,
    name: 'วัชรพงศ์ เพชรศิริ',
    role: 'เจ้าของเพจ Condo Diary<br/>กูรูวงการอสังหาริมทรัพย์ ',
    image: '/speakers/real-estate6.png'
  },
  {
    id: 7,
    name: 'สุรเชษฐ กองชีพ',
    role: 'กรรมการผู้จัดการ<br/>Property  DNA ',
    image: '/speakers/real-estate7.png'
  }
]

const digitalAssetSpeakers = [
  {
    id: 1,
    name: 'จิรายุส ทรัพย์ศรีโสภา',
    role: 'ผู้ก่อตั้ง Bitkub',
    image: '/speakers/digital1.png'
  },
  {
    id: 2,
    name: 'บุริศร์ จีระมะกร',
    role: 'ผู้ก่อตั้ง Finance Engine',
    image: '/speakers/digital2.png'
  },
  {
    id: 3,
    name: 'สัญชัย ปอปลี',
    role: 'Co-founder<br/>Cryptomind Group',
    image: '/speakers/digital3.png'
  },
  {
    id: 4,
    name: 'Michael APEmazing',
    role: 'นักลงงทุน NFT ',
    image: '/speakers/digital4.png'
  },
  {
    id: 5,
    name: 'ณัฏฐ์ จิตตมัย',
    role: 'เจ้าของเพจ เช้านี้',
    image: '/speakers/digital5.png'
  },
  {
    id: 6,
    name: 'นที เทพโภชน์',
    role: 'Founder Block Mountain',
    image: '/speakers/digital6.png'
  },
  {
    id: 7,
    name: 'พริษฐ์ บุญเลื่อน',
    role: 'ผู้เชี่ยวชาญด้าน DeFi<br/>บริษัท Cryptomind ',
    image: '/speakers/digital7.png'
  },
  {
    id: 8,
    name: 'กิตติพล ลีปีพัฒนวิทย์',
    role: 'CEO<br/>บริษัท เคมาร์ส สตูดิโอ จำกัด',
    image: '/speakers/digital8.png'
  }
]

const SpeakerList = () => {
  return (
    <>
      <h2 className="h2 fw-bold mt-5 pt-5 text-center">ผู้บริหารหลักสูตร</h2>
      <div className="row mt-4 justify-content-center">
        { executives.map((executive, index) => {
            return (
              <div className={`col-sm-6 col-md-4 col-lg-3 mb-4 ${index === 0 ? 'mobile-reorder' : ''}`} key={index}>
                <div className="card shadow-none speaker-item">
                  <div className="card-body text-center">
                    <img src={executive.image} className="speaker-profile img-fluid mb-3" alt={ executive.name } />

                    <div className="flex-fill mt-1">
                      <div className="fw-semibold h5" style={{ fontSize: 16 }}>{ executive.name }</div>
                      <p className="text-muted f-12 mb-0" dangerouslySetInnerHTML={{ __html: executive.role }} />
                    </div>
                  </div>
                </div>
              </div>     
            )
          })
        }
      </div>

      <h2 className="h2 fw-bold mt-5 text-center">Directors</h2>
      <div className="row mt-4 justify-content-center">
        { directors.map((director, index) => {
            return (
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
                <div className="card shadow-none speaker-item">
                  <div className="card-body text-center">
                    <img src={director.image} className="speaker-profile img-fluid mb-3" alt={ director.name } />

                    <div className="flex-fill mt-1">
                      <div className="fw-semibold h5" style={{ fontSize: 16 }}>{ director.name }</div>
                      <p className="text-muted f-12 mb-0" dangerouslySetInnerHTML={{ __html: director.role }} />
                    </div>
                  </div>
                </div>
              </div>     
            )
          })
        }
      </div>

      <h2 className="h2 fw-bold mt-5 text-center">Speaker : Bridge To STOCK</h2>

      <div className="row mt-4">
        { stockSpeakers.map((speaker, index) => {
            return (
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
                <div className="card shadow-none speaker-item">
                  <div className="card-body text-center">
                    <img src={speaker.image} className="speaker-profile img-fluid mb-3" alt={ speaker.name } />

                    <div className="flex-fill mt-1">
                      <div className="fw-semibold h5" style={{ fontSize: 16 }}>{ speaker.name }</div>
                      <p className="text-muted f-12 mb-0" dangerouslySetInnerHTML={{ __html: speaker.role }}  />
                    </div>
                  </div>
                </div>
              </div>     
            )
          })
        }
      </div>

      <h2 className="h2 fw-bold mt-5 text-center">Speaker : Bridge To Real Estate</h2>

      <div className="row mt-4">
        { realEstateSpeakers.map((speaker, index) => {
            return (
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
                <div className="card shadow-none speaker-item">
                  <div className="card-body text-center">
                    <img src={speaker.image} className="speaker-profile img-fluid mb-3" alt={ speaker.name } />
                    <div className="flex-fill mt-1">
                      <div className="fw-semibold h5" style={{ fontSize: 16 }}>{ speaker.name }</div>
                      <p className="text-muted f-12 mb-0" dangerouslySetInnerHTML={{ __html: speaker.role }} />
                    </div>
                  </div>
                </div>
              </div>     
            )
          })
        }
      </div>

      <h2 className="h2 fw-bold mt-5 text-center">Speaker : Bridge To Digital Asset</h2>

      <div className="row mt-4">
        { digitalAssetSpeakers.map((speaker, index) => {
            return (
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
                <div className="card shadow-none speaker-item">
                  <div className="card-body text-center">
                    <img src={speaker.image} className="speaker-profile img-fluid mb-3" alt={ speaker.name } />
                    <div className="flex-fill mt-1">
                      <div className="fw-semibold h5" style={{ fontSize: 16 }}>{ speaker.name }</div>
                      <p className="text-muted f-12 mb-0" dangerouslySetInnerHTML={{ __html: speaker.role }} />
                    </div>
                  </div>
                </div>
              </div>     
            )
          })
        }
      </div>
    </>
  )  
}

export default SpeakerList;