import './Mazk_MainPage.css';
import Header from "../header/Header_2";
import Banner1 from "./Mazk_Banner1";
import Banner2 from "../Main_Page/Mazk_Banner2";
import Banner3 from "../Main_Page/Mazk_Banner3";
import Banner7 from "../Main_Page/Mazk_Banner4";
import Roadmap from "../Main_Page/Mazk_Roadmap";
import NFT from "../Main_Page/Mazk_NFT";
import FAQ from "../Main_Page/Mazk_FAQ";
import React, { Component } from "react";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

export default function Mazk_MainPage() {
    const [lang, setLang] = useState(0);
    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        setLang(params.lang);
    }, [lang]);

    return (
        <div className="text-white page-reset">
            <Link to="https://bullmoonexclusive.com/">
            <div style={{ backgroundColor: "black", zIndex: "30000001" ,backgroundImage:"url('exclusive_tab4.jpg')", backgroundSize:"cover",backgroundPosition:"center", minHeight:"70px"}}>
                <center>
                    
                </center>
            </div>
            </Link>
            <div className="header_menu" id="top_menu" style={{ transition: 'all 1s' }}>
                <center><Header lang={lang} /></center>
            </div>
            <div style={{ transition: 'all 1s' }}>

                <Banner1 lang={lang} />
            </div>
            <div className="bg" style={{ transition: 'all 1s' }}>
                <Banner2 lang={lang} />
            </div>
            <div className="bg" style={{ transition: 'all 1s' }}>
                <NFT lang={lang} />
            </div>
            <div className="bg" style={{ transition: 'all 1s' }}>
                <Roadmap lang={lang} />
            </div>
            <div className="bg" style={{ transition: 'all 1s' }}>
                <Banner3 lang={lang} />
            </div>
            <div className="bg" style={{ transition: 'all 1s' }}>
                <Banner7 lang={lang} />
            </div>
            <div className="bg" style={{ transition: 'all 1s' }}>
                <FAQ lang={lang} />
            </div>
        </div>
    )

}