import "./Mazk_Roadmap.css";
import React, { Component } from "react"; 
var lang;

class Roadmap extends Component {
  componentDidMount() {
    window.addEventListener('resize',roadmapResize);
    roadmapResize();
  }

  componentDidUpdate(){
    lang = this.props.lang;
    roadmapResize();
  }

  componentWillUnmount() {
  }
  
  render() {
    return (
      <div id='roadmap_maindiv' >
        <div id='roadmap_container' >
          <div id="roadmap_container_header"style={{padding:'4vw',fontWeight:'900'}}><center>ROADMAP</center></div>
          <img id='roadmap_img' src="" />
          <br/>
        </div>
      </div>
    )
  }
}
export default Roadmap;

function roadmapResize() {
  if(window.innerWidth > 1000){
    if(lang != "en"){
      document.getElementById('roadmap_img').src = "roadmap_long2.png";
    } else {
      document.getElementById('roadmap_img').src = "roadmap_long_en.png";
    }
  }else{
    if(lang != "en"){
      document.getElementById('roadmap_img').src = "roadmap_stand2.png";
    } else {
      document.getElementById('roadmap_img').src = "roadmap_stand_en.png";
    }
  }
}