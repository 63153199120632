import "./HelpPage.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";

var myRFA;
var difftime , diffHour , diffHour24 , diffMinute ,diffSecond ,diffDay;
var interv5;
class Help extends Component {
    componentDidMount() {
    }

    componentWillUnmount(){
    }

    render(){
      return (
        <div style={{width:'100%',fontFamily:'prompt',fontSize:'35px',backgroundColor:'rgb(40,40,40)'}}>
          {/* <div style={{fontSize:'max(2vw,24px)', padding:'30px',width:'100%',height:'auto', display:'flex',justifyContent:'center' ,alignContent:'center', backgroundColor:'black'}}>
            <br/><br/><center><br/><br/>คอร์สแนะนำ NFT เบื้องต้นจากทาง Stock2Morrow</center><br/>
          </div>
          <div style={{width:'100%',height:'auto', display:'flex',justifyContent:'center' ,alignContent:'center', padding:'30px',backgroundColor:'black'}}>
            <a href="https://stock2morrow.com/course/28" ><img src="course1.jpg" style={{width:'min(700px,95vw)',height:'auto'}} /></a><br/><br/>
          </div> */}
          
          
          <center>
            <br/><br/>
            <button className="button_2" style={{fontSize:"28px",width:"370px"}}>
              <a href='https://page.line.me/stock2morrow?openQrModal=true' target='_blank'>ติดต่อซื้อผ่านเจ้าหน้าที่ คลิก</a>
            </button><br/>
          </center>
          <div style={{fontSize:'70px', width:'100%',height:'auto', display:'flex',justifyContent:'center' ,alignContent:'center', }}>
          
            <br/><br/>วิธีซื้อ NFT <br/><br/>
          </div>
          <div style={{height:'60vw',maxHeight:'600px',position:'relative',display:'flex',justifyContent:'center' ,alignContent:'center'}}>
            <div style={{width:'100%',maxWidth:'1000px', height:'100%'}}>
                <iframe style={{width:'100%',height:'100%'}} src="https://www.youtube.com/embed/EXCMkEjIhMM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          
          <br/>
          <div id="help_essentialDiv" style={{backgroundColor:'rgb(40,40,40)'}} >
              
              <img src="introToBuy.jpg" style={{width:'min(900px,95vw)',height:'auto'}} />    
          </div>
          <Link to="/">
            <a id="help_back_div">
              &lt;  BACK HOME 
            </a>
          </Link> 
        </div>
      )
    }
}
export default Help;