import "./Mazk_NFT.css";
import "./Mazk_Banner1.css";
import React, { Component } from "react"; 


class Roadmap extends Component {
  componentDidMount() {
   // window.addEventListener('resize',NFT_Resize);
   // NFT_Resize();
  }

  componentWillUnmount() {
  }
  
  render() {
    return (
      <div id='NFT_maindiv' >
        
        <div id='bullmoon_banner_logo2' >
            <br/><br/>
            BULL<font style={{color:'rgb(255,255,100)'}}>MOON</font> CLUB NFT
            <font style={{fontSize:'max(1vw,10px)',fontWeight:'400',lineHeight:'0px'}}><br />BY</font> <img style={{display:'inline-block',width:'max(13vw,120px)'}} src='s2m_logo.png' /> 
            <br/><br/>
        </div>
        <div id='NFT_container' >
          <div id='NFT_inner1' >
            <img className='NFT_item' src='nft1.jpg' />
            <img className='NFT_item' src='nft2.jpg' />
            <img className='NFT_item' src='nft3.jpg' />
            <img className='NFT_item' src='nft4.jpg' />
            <img className='NFT_item' src='nft5.jpg' />
            <img className='NFT_item' src='nft6.jpg' />
          </div>
        </div>
        <br/><br/><br/><br/>
      </div>
    )
  }
}
export default Roadmap;

function NFT_Resize() {
  if(window.innerWidth > 1000){
    document.getElementById('NFT_img').src = "roadmap_long2.png";
  }else{
    document.getElementById('NFT_img').src = "roadmap_stand2.png";
  }
}