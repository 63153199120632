import { Link } from "react-router-dom";
import Sticky from "react-sticky-el";
import Lottie from "react-lottie";
import "bootstrap/dist/css/bootstrap.min.css";
import Course2List from "../components/Course2List";
import Speaker2List from "../components/Speaker2List";
import * as investmentAnimation from "../assets/animations/investment.json";

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: investmentAnimation.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Course2Page = () => {
  return (
    <div className="page-remove-reset">
      <section>
        <div className="container course-container text-dark py-5">
          <div className="mb-3">
            <Link
              to="/"
              className="text-dark"
              style={{ textDecoration: "none", fontSize: 14 }}
            >
              &lt; Back Home
            </Link>
          </div>

          <img
            src="https://cdn.stock2morrow.com/images/bullmoon-banner3.jpg"
            alt="Bullmoon exclusive course"
            className="d-none d-md-block"
          />
          <img
            src="https://cdn.stock2morrow.com/images/bullmoon-banner-mobile3.jpg"
            alt="Bullmoon exclusive course"
            className="d-md-none"
          />

          <div className="mb-5 full-course-desc">
            <h1 className="h1 fw-bold mt-5 mb-2 text-center">
              หลักสูตรการลงทุนสำหรับผู้บริหาร<br /> ที่มาพร้อม Community คุณภาพแบบ Exclusive
            </h1>
            <h3 className="h3 fw-semibold mb-3 text-center text-primary">
              เปิดโลกและขยายกำแพงการลงทุนของคุณ สัมผัสประสบการณ์ในการเรียนรู้การลงทุนแบบ Exclusive
            </h3>

            <p className="text-center">
              โฟกัสใน 3 สินทรัพย์สำคัญของยุคนี้และอนาคต
            </p>

            <div className="mt-5 course-subject-list">
              <div className="course-subject-item">
                <img
                  src="https://cdn.stock2morrow.com/images/bullmoon-stock.png"
                  width="120"
                  alt="Bullmoon stock"
                  className="mx-auto"
                />
                <div className="fw-bold text-secondary">หุ้น</div>
              </div>
              <div className="course-subject-item">
                <img
                  src="https://cdn.stock2morrow.com/images/bullmoon-prop.png"
                  width="120"
                  alt="Bullmoon Digital Asset"
                  className="mx-auto"
                />
                <div className="fw-bold text-secondary">อสังหาฯ</div>
              </div>
              <div className="course-subject-item">
                <img
                  src="https://cdn.stock2morrow.com/images/bullmoon-digital.png"
                  width="120"
                  alt="Bullmoon Digital Asset"
                  className="mx-auto"
                />
                <div className="fw-bold text-secondary">Digital Asset</div>
              </div>
            </div>

            <p className="text-center mt-5 fw-bold h4">ทีมผู้จัดหลักสูตร</p>

            <ul
              className="list-unstyled mx-auto my-4"
              style={{ maxWidth: 655 }}
            >
              <li className="d-md-flex align-items-center flex-wrap mb-4 mt-1">
                <div className="mt-2 mx-auto" style={{ width: 230 }}>
                  <img
                    src="/stock2morrow.png"
                    alt=""
                    width="200"
                    className="mx-auto"
                  />
                </div>
                <div className="mt-2 flex-fill text-center text-md-start">
                  ชุมชน และแหล่งให้ความรู้ด้านการลงทุนหุ้น
                </div>
              </li>
              <li className="d-md-flex align-items-center flex-wrap mb-4 mt-1">
                <div className="mt-2 mx-auto" style={{ width: 230 }}>
                  <img
                    src="/bullmoon.png"
                    alt=""
                    width="100"
                    className="mx-auto"
                  />
                </div>
                <div className="mt-2 flex-fill text-center text-md-start">
                  ชุมชน และแหล่งให้ความรู้ด้านการลงทุน DIGITAL ASSET
                </div>
              </li>
              <li className="d-md-flex align-items-center flex-wrap mb-4 mt-1">
                <div className="mt-2 mx-auto" style={{ width: 230 }}>
                  <img
                    src="/2morrowscaler.png"
                    alt=""
                    width="200"
                    className="mx-auto"
                  />
                </div>
                <div className="mt-2 flex-fill text-center text-md-start">
                  ชุมชน และแหล่งให้ความรู้ด้านธุรกิจ
                </div>
              </li>
            </ul>

            <p className="text-center pt-2">
              หลักสูตร BullMoon Exclusive หลักสูตรการลงทุนรูปแบบใหม่ <br />
              ผสานความรู้การลงทุนแบบเอ็กซ์คลูซีฟจากตัวจริงของวงการ +
              เน็ตเวิร์คกิ้งคุณภาพ
            </p>

            <p className="text-center">
              ออกแบบหลักสูตรจากแนวคิดที่จะพาคุณมาเปิดโลกการลงทุนและขยายพอร์ตการลงทุนของคุณให้แข็งแกร่งยิ่งกว่าเดิม
              ด้วย 3 สินทรัพย์หลักของโลกการลงทุนยุคปัจจุบันและอนาคต
            </p>
          </div>
          <img
            src="https://cdn.stock2morrow.com/images/fullschedule-gen3.jpg"
            alt="Schedule"
            className="img-fluid"
          />
        </div>
      </section>

      <section className="bg-purple-gradient py-5">
        <div className="container course-container text-dark">
          <h2
            className="fw-bold h1 mb-2 text-center text-white"
            style={{ lineHeight: 1.5 }}
          >
            ประสบการณ์การเรียนลงทุนสุดเอ็กซ์คลูซีฟ
            <br />
            ที่คุณจะได้จากหลักสูตร BullMoon Exclusive
          </h2>

          <div className="row mt-5">
            <div className="col-md-4 mb-4">
              <div className="d-flex align-items-center">
                <img
                  src="/classroom.png"
                  alt="Bullmoon Classroom"
                  width="120"
                />
                <div className="ml-3 text-white" style={{ lineHeight: "24px" }}>
                  <div className="fw-bold h5">Exclusive Classroom</div>
                  <div className="f-16 mt-1">
                    วิทยากรหลากหลายท่าน ซึ่งล้วนแต่เป็นตัวจริงของวงการ
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="d-flex align-items-center">
                <img src="/trip.png" alt="Bullmoon Trip" width="120" />
                <div className="ml-3 text-white" style={{ lineHeight: "24px" }}>
                  <div className="fw-bold h5">TRIP 3 วัน 2 คืน</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="d-flex align-items-center">
                <img src="/visit.png" alt="Bullmoon Site Visit" width="120" />
                <div className="ml-3 text-white" style={{ lineHeight: "24px" }}>
                  <div className="fw-bold h5">Company Visit</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="d-flex align-items-center">
                <img src="/workshop.png" alt="Bullmoon Workshop" width="120" />
                <div className="ml-3 text-white" style={{ lineHeight: "24px" }}>
                  <div className="fw-bold h5">Workshop</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="d-flex align-items-center">
                <img src="/trader.png" alt="Bullmoon Trader" width="120" />
                <div className="ml-3 text-white" style={{ lineHeight: "24px" }}>
                  <div className="fw-bold h5">Overnight Trading</div>
                  <div className="f-16 mt-1">เกาะติดชีวิตเทรดเดอร์</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="d-flex align-items-center">
                <img
                  src="/networking.png"
                  alt="Bullmoon Networking"
                  width="120"
                />
                <div className="ml-3 text-white" style={{ lineHeight: "24px" }}>
                  <div className="fw-bold h5">Networking Party</div>
                  <div className="f-16 mt-1">แบบสนุก กำลังมันส์ กำลังดี</div>
                </div>
              </div>
            </div>
          </div>

          <div className="my-4 mx-auto" style={{ maxWidth: 750 }}>
          <video
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              controls
              controlsList="nodownload noremoteplayback"
              autoPlay
              muted
              loop
              className="d-none d-md-block"
            >
              <source
                src="https://cdn.stock2morrow.com/images/bullmoon-gen2.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>

            <video
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              controls
              controlsList="nodownload noremoteplayback"
              muted
              loop
              className="d-md-none"
            >
              <source
                src="https://cdn.stock2morrow.com/images/bullmoon-gen2.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video><br/>

            <video
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              controls
              controlsList="nodownload noremoteplayback"
              autoPlay
              muted
              loop
              className="d-none d-md-block"
            >
              <source
                src="https://cdn.stock2morrow.com/images/bullmoon-gen2-2.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>

            <video
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              controls
              controlsList="nodownload noremoteplayback"
              muted
              loop
              className="d-md-none"
            >
              <source
                src="https://cdn.stock2morrow.com/images/bullmoon-gen2-2.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>

            <div className="mt-5 pt-5">
              <h2 className="h2 text-white fw-bold text-center mb-5">
                บรรยากาศ คลาสเรียน
              </h2>
              <img
                src="https://cdn.stock2morrow.com/images/bullmoon-activity1.png"
                alt="Bullmoon Activity"
                className="img-fluid"
              />
              <h2 className="h2 text-white fw-bold text-center my-5 pt-5">
                บรรยากาศ COMMUNITY
              </h2>
              <img
                src="https://cdn.stock2morrow.com/images/bullmoon-activity2.png"
                alt="Bullmoon Activity"
                className="img-fluid"
              />
              <h2 className="h2 text-white fw-bold text-center my-5 pt-5">
                บรรยากาศ NETWORKING PARTY
              </h2>
              <img
                src="https://cdn.stock2morrow.com/images/bullmoon-activity3.png"
                alt="Bullmoon Activity"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="py-5" style={{ height: 600 }}>
        <div className="container course-container text-dark">
          <div className="row">
            <div className="col-md-6">
              <h2 className="h2 fw-bold mb-4 mt-4">หลักสูตรนี้เหมาะกับ</h2>

              <ul
                className="list-unstyled"
                style={{ fontSize: 18, lineHeight: "30px" }}
              >
                <li className="py-1 mb-2 d-flex">
                  <figure className="mr-3 mt-1">
                    <img
                      src="/check.png"
                      width="30"
                      alt=""
                      style={{ minWidth: 30 }}
                    />
                  </figure>
                  <div className="flex-fill">
                    นักธุรกิจที่ประสบความสำเร็จ และต้องการเชื่อมโลกธุรกิจ
                    ให้เข้ากับโลกของการลงทุน ที่หลากหลายกว่าเดิม
                  </div>
                </li>
                <li className="py-1 mb-2 d-flex">
                  <figure className="mr-3 mt-1">
                    <img
                      src="/check.png"
                      width="30"
                      alt=""
                      style={{ minWidth: 30 }}
                    />
                  </figure>
                  <div className="flex-fill">
                    นักธุรกิจที่พร้อมต่อยอดกับนักลงทุน
                  </div>
                </li>
                <li className="py-1 mb-2 d-flex">
                  <figure className="mr-3 mt-1">
                    <img
                      src="/check.png"
                      width="30"
                      alt=""
                      style={{ minWidth: 30 }}
                    />
                  </figure>
                  <div className="flex-fill">
                    ผู้ที่ลงทุนในหุ้น, อสังหา, Digital Asset อยู่แล้ว
                    แต่สนใจเพิ่มพูนความรู้ในสินทรัพย์ใหม่ๆ
                    เพื่อขยายพอร์ตการลงทุนให้แข็งแกร่ง มั่นคง
                  </div>
                </li>
                <li className="py-1 mb-2 d-flex">
                  <figure className="mr-3 mt-1">
                    <img
                      src="/check.png"
                      width="30"
                      alt=""
                      style={{ minWidth: 30 }}
                    />
                  </figure>
                  <div className="flex-fill">
                    ผู้ที่อยากมีเพื่อนใหม่ๆในวงการลงทุน เสริมคอนเนคชั่นคุณภาพ
                    ขยายโอกาสในการลงทุน
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-6 d-none d-md-block">
              <Lottie options={animationOptions} style={{ marginTop: -10 }} />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-purple-gradient py-5">
        <div className="container course-container">
          <img
            src="https://cdn.stock2morrow.com/images/bullmoon-schedule-gen3.png"
            className="img-fluid"
          />
        </div>
      </section>

      <section className="bg-light py-5">
        <div className="container course-container text-dark">
          {/* <Course2List /> */}
          <Speaker2List />
        </div>
      </section>

      <section className="bg-purple-gradient py-5">
        <div className="container course-container text-white">
          <h2 className="h1 fw-bold mt-5 mb-3 text-white text-center">
            ราคาหลักสูตร
          </h2>

          <hr
            className="mx-auto mb-4"
            style={{
              borderTopWidth: 3,
              borderTopColor: "#c7b48c",
              width: 200,
              opacity: 1,
            }}
          />

          <p className="text-center h3 fw-normal">
            <b className="text-primary h2 fw-bold">140,000</b> บาท ตลอดหลักสูตร
            <br />
          </p>
          <p className="text-center mb-4">(รวม VAT = 149,800 บาท)</p>

          <p className="h5 fw-normal text-center">
            รวม 1 Trip ในประเทศและกิจกรรม Overnight Trade เรียบร้อยแล้ว
          </p>

          <p className="h5 fw-normal text-center">
            พร้อมรับ certificate และ BullMoonClub NFT ฟรี
          </p>

          <h2 className="h1 fw-bold mt-5 pt-5 mb-3 text-white text-center">
            ช่องทางติดตามหลักสูตร
          </h2>

          <hr
            className="mx-auto mb-4"
            style={{
              borderTopWidth: 3,
              borderTopColor: "#c7b48c",
              width: 200,
              opacity: 1,
            }}
          />

          <div className="d-flex h4 fw-normal align-items-center justify-content-center">
            <img src="/facebook.png" alt="Bullmoon Facebook" width="45" />:{" "}
            <a
              href="https://www.facebook.com/bullmoonexclusive"
              target="_blank"
              rel="noreferrer"
              className="text-white text-decoration-none ml-3"
            >
              Bullmoon Exclusive
            </a>
          </div>

          <div className="d-flex h4 fw-normal align-items-center justify-content-center">
            <img src="/instagram.png" alt="Bullmoon Instagram" width="45" />:{" "}
            <a
              href="https://www.instagram.com/bullmoon.exclusive"
              target="_blank"
              rel="noreferrer"
              className="text-white text-decoration-none ml-3"
            >
              Bullmoon Exclusive
            </a>
          </div>

          <p className="h4 fw-normal text-center mt-5">
            สอบถามรายละเอียดหรือขอความช่วยเหลือ ได้ที่
          </p>

          <div className="d-flex align-items-center justify-content-center flex-wrap h4 fw-normal">
            <div className="d-inline-flex align-items-center mx-3">
              <img src="/line.png" alt="Bullmoon Line" width="45" />
              <a
                href="https://lin.ee/8SkbU2x"
                target="_blank"
                rel="noreferrer"
                className="text-white text-decoration-none"
              >
                @STOCK2MORROW
              </a>
            </div>
            <div className="d-inline-flex align-items-center mx-3">
              <img src="/call.png" alt="Bullmoon Contact" width="45" />
              09 0980 2196
            </div>
          </div>
        </div>
      </section>

      <Sticky mode="bottom" stickyStyle={{ zIndex: 10 }}>
        <div
          className="bg-white border-top text-end"
          style={{ paddingTop: 19, paddingBottom: 19 }}
        >
          <div className="container text-center">
            <a
              className="btn btn-primary btn-register rounded-pill d-inline-flex"
              href="https://forms.gle/j7mSype89n7L3LsLA"
              target="_blank"
              role="button"
            >
              สมัครหลักสูตร
            </a>
          </div>
        </div>
      </Sticky>
    </div>
  );
};

export default Course2Page;
