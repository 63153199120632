const monthList = [
  {
    title: 'MAY 2023',
    courses: [
      {
        location: 'OPENING DAY @Villa Deva',
        days: 'THU',
        dateNumber: '18',
        events: [
          {
            name: 'พิธีเปิดหลักสูตร & Special Talk: การลงทุนที่เปลี่ยนไปของโลกยุคใหม่',
            time: '12:00 - 19:00',
            speakers: 'ปิยพันธ์ วงศ์ยะรา (ป้อม) / ประกาศิต ทิตาราม (ปุย) / นิติพงษ์ หิรัญพงษ์ (ป๊อป) / อัญพัชร์ ทรัพย์ยืนยง (ยุ้ย) / พรชัย บุญญาทานวงศ์ (หนุ่ม) / กัณฑ์ คลอวุฒินันท์ / จิรายุส ทรัพย์ศรีโสภา (ท้อป) / สุธาทร สุทธิสนธิ์ (ท๊อป)'
          },
          {
            name: 'Welcoming Party',
            time: '19:00 - 21:00'
          }
        ]
      },
      {
        location: 'Trip @Pattaya',
        days: 'SAT - MON',
        dateNumber: '27 - 29',
        events: [
          {
            name: 'เปิดโลกการลงทุน',
            time: 'All Day',
            speakers: 'ชลเดช เขมะรัตนา (นึก) / ทิวา ชินธาดาพงศ์ (เซียนมี่) / โอฬาร ภัทรกอบกิตติ (บ๊อบ) / บุริศร์ จีระมะกร (ตระการ) / สุรเชษฐ กองชีพ (จ๊อก)'
          }
        ]
      }
    ]
  },
  {
    title: 'JUNE 2023',
    courses: [
      {
        location: 'Stock @Villa Deva',
        days: 'THU',
        dateNumber: '1',
        events: [
          {
            name: 'Class Room: Fundamental',
            time: '12:00 - 18:30',
            speakers: 'ชยนนท์ รักกาญจนันท์ (แบงค์) / ภาววิทย์ กลิ่นประทุม (แพ้ท) / อ.เอิญ สุริยะ​ฉาย'
          },
          {
            name: 'Networking',
            time: '18:30 - 21:00'
          }
        ]
      },
      {
        location: 'Stock Visit @Site',
        days: 'THU',
        dateNumber: '8',
        events: [
          {
            name: 'CEO Talk & Company Visit',
            time: '12:00 - 16:30',
            speakers: 'Listed Company'
          },
          {
            name: 'Class Room: Fundamental',
            time: '16:30 - 19:00',
            speakers: 'กวี ชูกิจเกษม'
          }
        ]
      },
      {
        location: 'Real Estate @Villa Deva',
        days: 'THU',
        dateNumber: '15',
        events: [
          {
            name: 'Class Room: วิธีลงทุนอสังหา',
            time: '12:00 - 19:00',
            speakers: 'วัชรพงศ์ เพชรศิริ (ป๊อป) / ศิรประภา รักษ์สุจริต (จิ๊ป) / แพรรินทร์ เรืองปัญญาวุฒิ (เอิง)'
          },
          {
            name: 'Networking',
            time: '19:00 - 21:00'
          }
        ]
      },
      {
        location: 'Digital Asset @Villa Deva',
        days: 'THU',
        dateNumber: '22',
        events: [
          {
            name: 'Class Room: สิ่งสำคัญต้องรู้ ก่อนเข้าลงทุนในสินทรัพย์ดิจิทัล',
            time: '12:00 - 18:30',
            speakers: 'สัญชัย ปอปลี (ซานเจย์) / ณัฎฐ์ จิตตมัย / กัณฑ์ คลอวุฒินันท์ / พรชัย บุญญาทานวงศ์ (หนุ่ม) / พริษฐ์ บุญเลื่อน (อ๊อก)'
          },
          {
            name: 'Networking',
            time: '19:00 - 21:00'
          }
        ]
      },
      {
        location: 'Site Visit @Site',
        days: 'THU',
        dateNumber: '29',
        events: [
          {
            name: 'Class Room: การเลือกอสังหา',
            time: '12:00 - 15:00',
            speakers: 'ประกาศิต ทิตาราม (ปุย) / อัญพัชร์ ทรัพย์ยืนยง (ยุ้ย)'
          },
          {
            name: 'Developer Talk & Site Visit',
            time: '15:00 - 18:30',
            speakers: 'พีระพงศ์ จรูญเอก (โด่ง) / โอภาส ถิรปัญญาเลิศ (ใหญ่)'
          }
        ]
      },
    ]
  },
  ,
  {
    title: 'JULY 2023',
    courses: [
      {
        location: 'Overnight Trading @Villa Deva',
        days: 'THU',
        dateNumber: '6',
        events: [
          {
            name: 'Class Room: Technical Analysis',
            time: '12:00 - 16:00',
            speakers: 'นิติพงษ์ หิรัญพงษ์ (ป๊อบ) / ประกาศิต ทิตาราม (ปุย)'
          },
          {
            name: 'ตามติดชีวิตเทรดเดอร์',
            time: '16:00 - 24:00',
            speakers: 'ปุณยวีร์ จันทรขจร (เป๊ก) / มานิตย์ ศรายุทธิกรณ์ (เบิร์ท) / ธนาเดช โสมบุตร (จักร)'
          }
        ]
      },
      {
        location: 'Digital Asset @Villa Deva',
        days: 'THU',
        dateNumber: '13',
        events: [
          {
            name: 'Class Room: โอกาส&ความลับ ในโลกสินทรัพย์ดิจิทัล',
            time: '12:00 - 19:00',
            speakers: 'Michael APEmazing / นที เทพโภชน์ (ป๊อบ) / กิตติพล ลีปิพัฒนวิทย์ (พล) / ปิยพันธ์ วงศ์ยะรา (ป้อม)'
          },
          {
            name: 'Networking',
            time: '19:00 - 21:00'
          }
        ]
      },
      {
        location: 'Celebration Day @Villa Deva',
        days: 'THU',
        dateNumber: '20',
        events: [
          {
            name: 'Class Room: Money & Portfolio Managment',
            time: '12:00 - 15:30',
            speakers: 'วิเชฐ ตันติวานิช (เว้) / พจน์ หะริณสุต (โน้ต)'
          },
          {
            name: 'Group Presentation',
            time: '15:30 - 17:00'
          },
          {
            name: 'พิธีปิดหลักสูตร',
            time: '17:30 - 19:00',
            speakers: 'วิชัย ทองแตง'
          },
          {
            name: 'Celebration Party',
            time: '19:00 - 21:00'
          }
        ]
      },
    ]
  }
]

const CourseList = () => {
  return (
    <>
      {
        monthList.map(month => {
          return (
            <div key={month.title}>
              <div key={monthList.title}>
                <h3 className="h3 fw-bold mb-4">{ month.title }</h3>
              </div>

              {
                month.courses.map((course, index) => {
                  return (
                    <div className="d-flex mb-5 course-item" key={index}>
                      <div className="course-datetime bg-purple-gradient">
                        <div className="fw-semibold">{ course.days }</div>
                        <h2 className="fw-bold mb-0">{ course.dateNumber }</h2>
                      </div>
                      <div className="flex-fill course-detail">
                        <h5 className="fw-semibold course-location mb-3">{ course.location }</h5>
                        { course.events.map(event => {
                          return <EventItem event={event} />
                        })}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </>
  )  
}

const EventItem = ({ event }) => {
  return (
    <div className="card shadow-none course-item">
      <div className="card-body">
        <div className="d-flex align-items-center">
          <div className="me-5 fw-semibold text-muted" style={{ minWidth: 120 }}>
            { event.time }
          </div>
          <div className="flex-fill">
            <div className="course-name fw-semibold">
              { event.name }
            </div>
            { event.speakers &&
              <div className="course-speaker mt-1 d-flex mt-2 pt-2 border-top" style={{ lineHeight: '21px' }}>
                Speakers: <div className="flex-fill ms-2 text-muted">{ event.speakers }</div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseList;