import "./Mazk_Banner2.css";
import * as THREE from 'three';
import React, { Component } from "react"; 
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';
import { Link } from "react-router-dom";
//import { DebugEnvironment } from 'three/examples/jsm/environments/DebugEnvironment.js';
//import { ColorSpaceNode, MeshStandardNodeMaterial } from 'three/examples/jsm/nodes/Nodes.js';

var w , h;
var renderer = new THREE.WebGLRenderer({alpha:true, antialias:true});
var camera;
var clock = new THREE.Clock();
var mixer1;
var obj1;
var previousWidth;

var acdx = 0 , acdy = 0;
var vnmu = true;
var bw , bl, tttt;
var divw1;
var btt;

var fng1 = "test1_6.glb"
var fr = 1;
var offY = 0;
var zw = 50;
var jios = 0;
var scene = new THREE.Scene();
var fov1 = 22;
var topStory1 = 0;
var myRFA;
var loader;
var h1=0;



function Item1({ version}) {
  return (
    <div>
      สิทธิพิเศษเมื่อเข้าร่วม
      <font style={{color:'yellow'}}> คลับคุณภาพ </font>
    </div>
  ) ;
}

class Banner1 extends Component {
  componentDidMount() {
    
  }

  componentWillUnmount() {
  }
  
  render() {
    return (
      <div id='bullmoon_banner2_container'>
        {/************************************************/}
        <div id='bullmoon_banner2_maindiv'>
            
          
          {/************************************************/}
          <div id='bullmoon_banner2_div2'>
            <center>
              <div id='bullmoon_banner2_div2_inner1'>
                <center>
                <div id='bullmoon_banner2_logo' >
                  { this.props.lang != "en" ?
                      <div> สิทธิพิเศษเมื่อเข้าร่วม <font style={{color:'yellow'}}> คลับคุณภาพ </font> </div>
                      : <div> SPECIAL <font style={{color:'yellow'}}> PRIVILEGES </font></div>
                  }
                </div>

                <div className='bullmoon_banner2_div2_inner2_1'>

                  <div className='bullmoon_banner2_div2_inner2_1_bullet'>
                    <img className='bullmoon_banner2_div2_inner2_1_bullet_img' src="bullet1.png" ></img>
                  </div>
                  <br/><br/>
                  <div className='bullmoon_banner2_div2_inner2_1_text'>
                  <font className='bullmoon_banner2_div2_inner2_1_text_big1'>{this.props.lang != "en" ? "เข้าร่วมกิจกรรมพิเศษประจำเดือนเฉพาะผู้ถือNFT" : "Access to exclusive monthly NFT activities for holders." }</font>
                  <br/><font className='bullmoon_banner2_div2_inner2_1_text_big2'>(**Exclusive)</font>
                  </div>
                </div>
                
                { this.props.lang != "en" && 
                  <div className='bullmoon_banner2_div2_inner2_1'>
                    <div className='bullmoon_banner2_div2_inner2_1_bullet'>
                      <img className='bullmoon_banner2_div2_inner2_1_bullet_img' src="bullet1.png" ></img>
                    </div>
                    <div className='bullmoon_banner2_div2_inner2_1_text'>
                    <font className='bullmoon_banner2_div2_inner2_1_text_big1'>เข้าร่วม Party ปีใหม่ Networking และมุมมองจากกูรูในเทรนด์การลงทุนใน Q1-2566</font>
                    <br/><font className='bullmoon_banner2_div2_inner2_1_text_big2'>(**Exclusive)</font>
                    </div>
                  </div>
                }

                <div className='bullmoon_banner2_div2_inner2_1'>
                  <div className='bullmoon_banner2_div2_inner2_1_bullet'>
                    <img className='bullmoon_banner2_div2_inner2_1_bullet_img' src="bullet1.png" ></img>
                  </div>
                  <div className='bullmoon_banner2_div2_inner2_1_text'>
                  <font className='bullmoon_banner2_div2_inner2_1_text_big1'>{this.props.lang != "en" ? "โอกาสเข้าร่วมเป็น Partner ทางธุรกิจและสิทธิประโยชน์มากมาย" : "Business partnership opportunities with various chain business industries, Extra benefits of Stock2morrow’s partners" }</font>
                  </div>
                </div>

                <div className='bullmoon_banner2_div2_inner2_1'>
                  <div className='bullmoon_banner2_div2_inner2_1_bullet'>
                    <img className='bullmoon_banner2_div2_inner2_1_bullet_img' src="bullet1.png" ></img>
                  </div>
                  <div className='bullmoon_banner2_div2_inner2_1_text'>
                  <font className='bullmoon_banner2_div2_inner2_1_text_big1'>{this.props.lang != "en" ? "ได้สิทธิ์ก่อนใครในโครงการ Metaverse Bangkok ปี 2566" : "Access to Metaverse Project Bangkok in 2023."}</font>
                  </div>
                </div>

                <div className='bullmoon_banner2_div2_inner2_1'>
                  <div className='bullmoon_banner2_div2_inner2_1_bullet'>
                    <img className='bullmoon_banner2_div2_inner2_1_bullet_img' src="bullet1.png" ></img>
                  </div>
                  <div className='bullmoon_banner2_div2_inner2_1_text'>
                  <font className='bullmoon_banner2_div2_inner2_1_text_big1'>{this.props.lang != "en" ? "ส่วนลด 10% ในทุกสินค้าและบริการจาก stock2morrow เช่น หนังสือ สัมมนา อีเวนต์ ฯลฯ (ตั้งแต่ 8 ส.ค. 65 เป็นต้นไป)" : "10% discount of Stock2morrow goods and services." } </font>
                  </div>
                </div>
              </center>
            </div>
            </center>
          </div>
          {/************************************************/}
        </div>
      </div>
    )
  }
}
export default Banner1;
