import "./Mazk_Banner1.css";
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';
import { Link } from "react-router-dom";
import React, { Component } from "react"; 

//import { DebugEnvironment } from 'three/examples/jsm/environments/DebugEnvironment.js';
//import { ColorSpaceNode, MeshStandardNodeMaterial } from 'three/examples/jsm/nodes/Nodes.js';

var w , h;
var renderer = new THREE.WebGLRenderer({alpha:true, antialias:true});
var camera;
var clock = new THREE.Clock();
var mixer1;
var obj1;
var previousWidth;


var acdx = 0 , acdy = 0;
var vnmu = true;
var bw , bl, tttt;
var divw1;
var btt;

var fng1 = "test1_6.glb"
var fr = 1;
var offY = 0;
var zw = 50;
var jios = 0;
var scene = new THREE.Scene();
var fov1 = 22;
var topStory1 = 0;
var myRFA;
var loader;
var h1=0;
var VDO1isPaused;
var difftime , diffHour , diffHour24 , diffMinute ,diffSecond ,diffDay;
var interv5;
var banner_set;
var didRefresh;

class Banner1 extends Component {
  didRefresh = false;
  componentDidMount() {
    banner1_resize();
    var animate = function () {
      myRFA = requestAnimationFrame( animate );
      h1 += 6;
      var th = 'hue-rotate('+ h1 + 'deg)';
      if(document.getElementById('t1')) {
        document.getElementById('t3').style.filter = th;
        
        document.getElementById('imgb1').style.filter = 'grayscale(100%)';
        document.getElementById('imgb1').style.transform = 'scale(0.8)';
        document.getElementById('imgb2').style.filter = 'grayscale(100%)';
        document.getElementById('imgb2').style.transform = 'scale(0.8)';
        document.getElementById('imgb3').style.filter = th;
        document.getElementById('imgb3').style.transform = 'scale('+ (.9 + .15*Math.abs(Math.sin(.8 * h1 * .0174))) +')';
      }
    };
    animate(); 
    
    const getCountDown = () => {
      const today = new Date();
      //const presaleDate = new Date((1655996400)*1000); 
      const presaleDate = new Date("October 29, 2022 20:00:00+07:00");
      //TimeZone.setDefault( TimeZone.getTimeZone("UTC"));
      //alert(presaleDate.toUTCString());
      difftime = presaleDate.getTime() - today.getTime();
      if(difftime < 0){
        difftime = 0;
        clearInterval(interv5);
        document.getElementById('countdownpart').style.display = 'none';
        document.getElementById('btn_comment').style.display = 'none';
        //document.getElementById('btn_text1').style.display = 'block';
        //document.getElementById('btn_text2').style.display = 'block';
        document.getElementById('btn_text1').innerHTML = "PUBLIC MINT<span style='color:darkorange;font-size:16px;letter-spacing:4px'><br/>CLICK HERE</span>";
        document.getElementById('btn_text2').innerHTML = "PUBLIC MINT<span style='color:darkorange;font-size:16px;letter-spacing:4px'><br/>CLICK HERE</span>";    
      }
      diffDay = parseInt(difftime/3600/1000/24);
      diffHour = parseInt(difftime/3600/1000);
      diffMinute = Math.ceil(difftime/60/1000) - diffHour*60 - 1;
      diffSecond = parseInt(difftime/1000)%60;
      diffHour24 = parseInt(difftime/3600/1000) % 24;
      if(diffMinute < 0) diffMinute=0;
    };
  
    const updateInterval = () => {
      getCountDown();
      document.getElementById('countdown_day').innerHTML = (diffDay < 10 ? '0':'') + diffDay;
      document.getElementById('countdown_hrs').innerHTML = (diffHour24 < 10 ? '0':'') + diffHour24;
      document.getElementById('countdown_min').innerHTML = (diffMinute < 10 ? '0':'') + diffMinute;
      document.getElementById('countdown_sec').innerHTML = (diffSecond < 10 ? '0':'') + diffSecond;
    }
    getCountDown();
    interv5 = setInterval( updateInterval , 1000);
  }

  componentWillUnmount() {
    clearInterval(interv5);
    cancelAnimationFrame(myRFA);;
  }

  render() {
    return (
      <div >
        {/************************************************/}
        
        <div id='bullmoon_banner_maindiv'>
          <div id='bullmoon_banner_logo'>
              <img  id='bullmoon_banner_logoimg' src="logo_BMC2.png" /><br/>
          </div>
            
          <div id='bullmoon_banner_btn' >
            <center>
              <div id='linkmint1'>
                <Link to="/mintpub"> <button id='btn_text1' className="button_1" > PUBLIC SALE </button> </Link> 
                <br/><div className="button_explain" id="btn_comment" style={{display:'none'}}>หมดเขต moonlist วันที่ 27 ก.ค. 65 เวลา 10:00 <br/>(จำกัดจำนวนไม่เกิน 5 ชิ้นต่อกระเป๋า)</div> 
              </div><br/>
              <div id='help_div'>
                <Link to="/help">   <a id="help_button" href="help" > <img src='help_icon1.png' style={{width:'30px',margin:'3px',padding:'2px'}}/> 
                {this.props.lang != "en" ? "ช่วยเหลือมือใหม่" : "HELP"}
                 </a>
                </Link>
                {this.props.lang != "en" &&
                 <button><a className="text-white" href='https://page.line.me/stock2morrow?openQrModal=true' target='_blank'><br/>(หรือติดต่อซื้อผ่านเจ้าหน้าที่ คลิกที่นี่ )</a></button> }
                
              </div>
               {/* <div id='bullmoon_banner_mini_logo'>
                <font style={{fontSize:'max(2vw,20px)',fontWeight:'400',lineHeight:'0px'}}><br />BY</font> <img style={{display:'inline-block',width:'max(20vw,200px)'}} src='s2m_logo.png' /> 
              </div> */}
            </center> 
          </div>
          <center>
          <div id='bullmoon_banner_bull_out' >
            <div id='bullmoon_banner_bull' >
                <img id="banner_long" src="/banner_long.jpg"></img>
            </div>
          </div>
          </center>
        </div>
        {/************************************************/}
        <div id='vdo_banner_new'>
        <video id="myVideo1" src="loop6.mp4" playsInline loop muted autoPlay preload="true" ></video>
        </div>
        
        {/************************************************/}
        <div id='bullmoon_banner_div2'>
          <center>
          <div id='bullmoon_banner_div2_inner1' >
            <center>
              <br/><br/>
              <div className="banner_div2_motto">
                  <div className="banner_div2_motto_big1">
                  &#x2308;ART<br/><div className="banner_div2_motto_small" >&nbsp; &nbsp; &nbsp; &nbsp;  Digital Asset</div>
                  </div>
                  <div className="banner_div2_motto_plus">+</div>
                  <div className="banner_div2_motto_big2">
                    &nbsp;COMMUNITY&nbsp;<br/><div className="banner_div2_motto_small">ชุมชนคนคุณภาพ</div>
                  </div>
                  <div className="banner_div2_motto_plus">+</div>
                  <div className="banner_div2_motto_big3">
                    &nbsp;UTILITY&#x230B;<br/><div className="banner_div2_motto_small">กิจกรรม Exclusive&nbsp; &nbsp; &nbsp; &nbsp;</div>
                  </div>
              </div>
            </center>
          </div>

          <div id='bullmoon_banner_div2_inner2' style={{backgroundColor:'rgba(255,255,255,0.5)'}}>
            {this.props.lang != "en" ? "มิติใหม่ NFT ใบผ่านสู่สังคมคุณภาพ" : "WELCOME TO"} <br/>
            <font className='BB'>BULL<font style={{color:'rgb(255,255,100)'}}>MOON</font>CLUB</font>
            <br/><font className='BB2'>{this.props.lang != "en" ? "NFTของชุมชนนักลงทุน ": "NFT of Investor community " }</font><br/><br/>
            <hr style={{width:'70%',lineHeight:'0vw'}}/>

            <div className='bullmoon_banner_div2_inner2_1' style={{color:'rgb(160,160,160)',fontSize:'20px'}}>
              <div className='bullmoon_banner_div2_inner2_1_bullet'>
                <img id='imgb1' className='bullmoon_banner_div2_inner2_1_bullet_img' src="bullet1.png" ></img>
              </div>
              <div className='bullmoon_banner_div2_inner2_1_text'>
                <div id='t1'><font className='bullmoon_banner_div2_inner2_1_text_big1' style={{color:'rgb(160,160,160)',fontSize:'max(33px,1.2vw)',lineHeight:'max(33px,1.2vw)'}}>MOONLIST</font></div>
                <font className='bullmoon_banner_div2_inner2_1_text_big2' style={{color:'rgb(160,160,160)',fontSize:'max(17px,.4vw)'}}>{this.props.lang != "en" ? "หมดเขตแล้ว": "EXPIRED" }
                <br/><font className='bullmoon_banner_div2_inner2_1_text_big3' style={{color:'rgb(160,160,160)',fontSize:'max(30px,1.2vw)',lineHeight:'max(30px,1.2vw)', filter:'none'}}>0.10 ETH</font></font>
              </div>
            </div>
            <div className='bullmoon_banner_div2_inner2_1'>
              <div className='bullmoon_banner_div2_inner2_1_bullet'>
                <img id='imgb2' className='bullmoon_banner_div2_inner2_1_bullet_img' src="bullet1.png" ></img>
              </div>
              <div className='bullmoon_banner_div2_inner2_1_text'>
                <div id='t4'><font className='bullmoon_banner_div2_inner2_1_text_big1' style={{color:'rgb(160,160,160)',fontSize:'max(33px,1.2vw)'}}>EARLY BIRD</font></div>
                <font className='bullmoon_banner_div2_inner2_1_text_big2'  style={{color:'rgb(160,160,160)',fontSize:'max(19px,.5vw)',lineHeight:'max(19px,.5vw)'}}> {this.props.lang != "en" ? "หมดเขตแล้ว": "EXPIRED" }
                <br/><font className='bullmoon_banner_div2_inner2_1_text_big3' style={{color:'rgb(160,160,160)',fontSize:'max(30px,1.2vw)',lineHeight:'max(30px,1.2vw)', filter:'none'}}>0.12 ETH</font></font>
              </div>
            </div>
            
            <div className='bullmoon_banner_div2_inner2_1'>
              <div className='bullmoon_banner_div2_inner2_1_bullet'>
                <img id='imgb3' className='bullmoon_banner_div2_inner2_1_bullet_img' src="bullet1.png" ></img>
              </div>
              <div className='bullmoon_banner_div2_inner2_1_text'>
                <div id='t3'><font className='bullmoon_banner_div2_inner2_1_text_big1' style={{fontSize:'max(45px,1.6vw)',lineHeight:'max(45px,1.6vw)'}}>PUBLIC SALE</font></div>
                <font className='bullmoon_banner_div2_inner2_1_text_big2'>{this.props.lang != "en" ? "เริ่ม 30 ตุลาคม 2565 ": "start on 30/10/2022 " }<br/>{this.props.lang != "en" ? "(เวลา 20:00) ": "(20:00 GMT+7) " }
                <br/><font className='bullmoon_banner_div2_inner2_1_text_big3' style={{fontSize:'max(40px,2.5vw)',lineHeight:'max(40px,2.5vw)'}}>0.15 ETH</font></font><br/><br/>
              </div>
            </div>

            
            {/* <br/><button className="button_1" onClick={() => {window.open('https://docs.google.com/forms/d/1W5uSXEgP1r93dWJXmxS33H1Nyx4f4qAZGN92FRphIfw/edit', "_blank")}} >ลงทะเบียนจองสิทธิ์ MOONLIST</button> */}
          </div>
          
          <div className ='bullmoon_mint_div'>
            <div className ='bullmoon_banner_div2_cht'>
              <div id='countdownpart' style={{display:'none'}}>
                COUNTDOWN TO PUBLIC SALE<br/>
                <div className ='bullmoon_banner_div2_countdown'>
                  <div  id="countdown_day" className="digitcount">00</div>:
                  <div  id="countdown_hrs" className="digitcount">00</div>:
                  <div  id="countdown_min" className="digitcount">00</div>:
                  <div  id="countdown_sec" className="digitcount">00</div>
                </div>
                
              </div>
              <div id='linkmint2'>
                {/* <a id='linkmint2_1' rel="noreferrer" href="https://opensea.io/collection/bullmoonclub-nft" target={'_blank'}  >
                  <img style={{width:'max(60px,4vw)'}} alt="" src="opensea.svg"  /><br/>
                </a> */}
                <div id='linkmint2_2'> <Link to="/mintpub">  <div className="button_1" id='btn_text2' > PUBLIC SALE </div> </Link> <br/><br/></div>
              </div>
              <center>
              
              <span style={{fontSize:'50px'}}>5,500 NFTs</span><br/>
              {this.props.lang != "en" && "เป็นการสุ่ม Mint NFTs"} <img className="pyramid1" src='pyramid1.png' />
              </center>
            </div>
          </div>

          </center>
        </div>
        {/************************************************/}
      </div>
    )
  }
}
export default Banner1;

function banner1_resize() {
  var cusid_ele = document.getElementsByClassName('banner_div2_motto_plus');
  for (var i = 0; i < cusid_ele.length; ++i) {
      var item = cusid_ele[i];  
      if(window.innerWidth < 1000){
        item.style.width = '3vw';
      }else{
        item.style.width = '6vw'
      }
  }
}


/* function Banner_resizing(e) {
  var video2 = document.getElementById('myVideo1');
  if(video2){
    alert('ss');
    if((window.innerWidth < 1000)){
      video2.load();
      video2.play();
    }
  }
  
  if(pw_width != window.innerWidth) {
    if((window.innerWidth < 1000)){
      if(video.getAttribute('src') != 'banner1_vertical.mp4' ){
        video.setAttribute('src', 'banner1_vertical.mp4');
        video.load();
        video.play();
      }
    }else{
      if(video.getAttribute('src') != "banner1.mp4"){
        video.setAttribute('src', 'banner1.mp4');
        video.load();
        video.play();
      }
    }
  }
  //pw_width = window.innerWidth;
} */

/* window.onbeforeunload = function() {
  window.scrollTo(0, 0);
};
window.onload = function() {
  window.scrollTo(0, 0);
};
*/