import { Link } from "react-router-dom";
import Sticky from 'react-sticky-el'
import Lottie from "react-lottie"
import 'bootstrap/dist/css/bootstrap.min.css';
import CourseList from "../components/CourseList";
import SpeakerList from "../components/SpeakerList";
import * as investmentAnimation from "../assets/animations/investment.json"

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: investmentAnimation.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
}

const CoursePage = () => {
  return (
    <div className="page-remove-reset">
      <section>
        <div className="container course-container text-dark py-5">
          <div className="mb-3">
            <Link to="/" className="text-dark" style={{ textDecoration: 'none', fontSize: 14 }}>
              &lt; Back Home
            </Link>
          </div>

          <img src="/bullmoon-banner.jpg" alt="Bullmoon exclusive course" className="d-none d-md-block" />
          <img src="/bullmoon-banner-square.jpg" alt="Bullmoon exclusive course" className="d-md-none"/>

          <div className="mb-5 full-course-desc">
            <h1 className="h1 fw-bold mt-5 mb-2 text-center">
              เปิดประตูสู่การลงทุน !!
            </h1>
            <h3 className="h3 fw-semibold mb-3 text-center">
              ด้วยหลักสูตรการลงทุน <b className="text-primary" style={{ letterSpacing: 2 }}>BullMoon Exclusive</b>
            </h3>

            <p className="text-center">
              เปิดประสบการณ์ในโลกของการลงทุนใน 3 สินทรัพย์หลักของยุคนี้และอนาคต
            </p>

            <div className="mt-5 course-subject-list">
              <div className="course-subject-item">
                <img src="/stock.png" width="120" alt="Bullmoon stock" className="mx-auto" />
                <div className="fw-bold">หุ้น</div>
              </div>
              <div className="course-subject-item">
                <img src="/prop.png" width="120" alt="Bullmoon Digital Asset" className="mx-auto" />
                <div className="fw-bold">อสังหาฯ</div>
              </div>
              <div className="course-subject-item">
                <img src="/digital.png" width="120" alt="Bullmoon Digital Asset" className="mx-auto" />
                <div className="fw-bold">Digital Asset</div>
              </div>
            </div>

            <p className="text-center mt-5">
              เน้นความรู้ <b>(Knowledge)</b> จากผู้เชี่ยวชาญในแต่ละสินทรัพย์<br />
              เรียนจากขั้นเริ่มต้นจนถึงขั้นสูง  ออกแบบเนื้อหาด้วยการจับมือกันของ 4 สังคมนักลงทุน คือ
            </p>

            <ul className="list-unstyled mx-auto my-4" style={{ maxWidth: 655 }}>
              <li className="d-md-flex align-items-center flex-wrap mb-4 mt-1">
                <div className="mt-2 mx-auto" style={{ width: 230 }}>
                  <img src="/stock2morrow.png" alt="" width="200" className="mx-auto" />
                </div>
                <div className="mt-2 flex-fill text-center text-md-start">ชุมชน และแหล่งให้ความรู้ด้านการลงทุนหุ้น</div>
              </li>
              <li className="d-md-flex align-items-center flex-wrap mb-4 mt-1">
                <div className="mt-2 mx-auto" style={{ width: 230 }}>
                  <img src="/bullmoon.png" alt="" width="100" className="mx-auto" />
                </div>
                <div className="mt-2 flex-fill text-center text-md-start">ชุมชน และแหล่งให้ความรู้ด้านการลงทุน DIGITAL ASSET</div>
              </li>
              <li className="d-md-flex align-items-center flex-wrap mb-4 mt-1">
                <div className="mt-2 mx-auto" style={{ width: 230 }}>
                  <img src="/2morrowscaler.png" alt="" width="200" className="mx-auto" />
                </div>
                <div className="mt-2 flex-fill text-center text-md-start">ชุมชน และแหล่งให้ความรู้ด้านธุรกิจ</div>
              </li>
            </ul>

            <p className="text-center pt-2">
              มาพร้อมกับ <b>Networking</b> ดีๆจากผู้เข้าร่วมหลักสูตร ที่คัดสรรมาเพื่อเป็นเพื่อนร่วมคิดในการลงทุนจากนี้ไป
            </p>

            <p className="text-center">
              ด้วยคอนเซ็ปท์ของการสอนในทั้ง 3 สินทรัพย์แบบปูพื้นฐานให้แน่นและเสริมด้วยเคล็ดลับการลงทุนให้พอร์ตฯเติบโตแบบมีความสุขในการลงทุน ต่อยอดให้เงินทำงาน ควบคู่กับธุรกิจหรืองานประจำที่ทำ หรือ เตรียมพร้อมให้เกิดอิสระในรูปแบบการเป็นนักลงทุนเต็มตัว
            </p>
          </div>
        </div>
      </section>

      <section className="bg-purple-gradient py-5">
        <div className="container course-container text-dark">
          <h2 className="fw-bold h1 mb-2 text-center text-white">
            พร้อมเปิดมิติใหม่ของหลักสูตร
          </h2>
          
          <div className="row mt-5">
            <div className="col-md-4 mb-4">
              <div className="d-flex align-items-center">
                <img src="/trader.png" alt="Bullmoon Trader" width="120" />
                <div className="ml-3 text-white">
                  เกาะติดชีวิตเทรดเดอร์ใน OVERNIGHT TRADE
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="d-flex align-items-center">
                <img src="/trip.png" alt="Bullmoon Trip" width="120" />
                <div className="ml-3 text-white">
                  1 TRIP 3 วัน 2 คืน
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="d-flex align-items-center">
                <img src="/visit.png" alt="Bullmoon Site Visit" width="120" />
                <div className="ml-3 text-white">
                  SITE VISIT
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="d-flex align-items-center">
                <img src="/workshop.png" alt="Bullmoon Workshop" width="120" />
                <div className="ml-3 text-white">
                  WORKSHOP
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="d-flex align-items-center">
                <img src="/classroom.png" alt="Bullmoon Classroom" width="120" />
                <div className="ml-3 text-white">
                  25 CLASSROOMS<br />
                  35 SPEAKERS<br />
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="d-flex align-items-center">
                <img src="/networking.png" alt="Bullmoon Networking" width="120" />
                <div className="ml-3 text-white">
                  NETWORKING PARTY แบบสนุก กำลังมันส์ กำลังดี
                </div>
              </div>
            </div>
          </div>

          <div className="my-4 mx-auto" style={{ maxWidth: 750 }}>
            <video 
              style={{ width: '100%', height: '100%', objectFit: 'cover'}}
              controls
              controlsList="nodownload noremoteplayback"
              autoPlay
              muted
              loop
              className="d-none d-md-block"
            >
              <source src="https://cdn.stock2morrow.com/images/bullmoon-newfont.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            <video 
              style={{ width: '100%', height: '100%', objectFit: 'cover'}}
              controls
              controlsList="nodownload noremoteplayback"
              muted
              loop
              className="d-md-none"
            >
              <source src="https://cdn.stock2morrow.com/images/bullmoon-newfont.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>

      <section className="py-5" style={{ height: 600}}>
        <div className="container course-container text-dark">
          <div className="row">
            <div className="col-md-6">
              <h2 className="h2 fw-bold mb-4 mt-4">
                หลักสูตรนี้เหมาะกับ
              </h2>
              
              <ul className="list-unstyled" style={{ fontSize: 18, lineHeight: '30px' }}>
                <li className="py-1 mb-2 d-flex align-items-center">
                  <img src="/check.png" width="30" alt="" className="mr-3" />
                  ผู้สนใจในการลงทุน (มือใหม่)
                </li>
                <li className="py-1 mb-2 d-flex align-items-center">
                  <img src="/check.png" width="30" alt="" className="mr-3" />
                  นักลงทุนในหุ้น, อสังหา, Digital Asset ที่สนใจเพิ่มพูนความรู้ในสินทรัพย์ใหม่ให้ตนเอง
                </li>
                <li className="py-1 mb-2 d-flex align-items-center">
                  <img src="/check.png" width="30" alt="" className="mr-3" />
                  ผู้ที่สนใจในการสร้างมิตรภาพดีๆกับเพื่อนใหม่ที่จะร่วมเส้นทางการลงทุน
                </li>
                <li className="py-1 mb-2 d-flex align-items-center">
                  <img src="/check.png" width="30" alt="" className="mr-3" />
                  เจ้าของกิจการ
                </li>
                <li className="py-1 mb-2 d-flex align-items-center">
                  <img src="/check.png" width="30" alt="" className="mr-3" />
                  พนักงานบริษัท
                </li>
                <li className="py-1 mb-2 d-flex align-items-center">
                  <img src="/check.png" width="30" alt="" className="mr-3" />
                  นักลงทุนอิสระ
                </li>
                <li className="py-1 mb-2 d-flex align-items-center">
                  <img src="/check.png" width="30" alt="" className="mr-3" />
                  ผู้เชี่ยวชาญเฉพาะด้าน
                </li>
                <li className="py-1 mb-2 d-flex align-items-center">
                  <img src="/check.png" width="30" alt="" className="mr-3" />
                  งานอิสระ
                </li>
              </ul>
            </div>
            <div className="col-md-6 d-none d-md-block">
              <Lottie options={animationOptions} style={{ marginTop: -10 }} />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light py-5">
        <div className="container course-container text-dark">
          <CourseList />
          <SpeakerList />
        </div>
      </section>

      <section className="bg-purple-gradient py-5">
        <div className="container course-container text-white">
          <h2 className="h1 fw-bold mt-4 mb-3 text-white text-center">
            กำหนดการ
          </h2>
          <hr className="mx-auto mb-4" style={{ borderTopWidth: 3, borderTopColor: '#c7b48c', width: 200, opacity: 1 }} />

          <p className="text-center h4 fw-normal mt-5">
            18 พฤษภาคม - 20 กรกฎาคม 2566
          </p>

          <p className="text-center h5 fw-normal mt-4 f-15-xs" style={{ lineHeight: '40px' }}>
            เรียนทุกวันพฤหัสบดี เวลา 12:00-19:00 น.<br />
            และวันที่มีกิจกรรม Networking เวลา 12:00-21:00 น.
          </p>

          <p className="text-center h4 fw-normal mt-5">
            27-29 พฤษภาคม 2566 : ทริปพัทยา
          </p>

          <p className="text-center h5 fw-normal mt-4 f-15-xs" style={{ lineHeight: '40px' }}>
            หมายเหตุ<br />
            - รับประทานอาหารร่วมกัน เวลา 12.00 น.<br />
            - ดูกำหนดการแบบละเอียด ได้จากตารางเรียนหลักสูตร<br />
          </p>

          <h2 className="h1 fw-bold mt-5 pt-5 mb-3 text-white text-center">
            ราคาหลักสูตร
          </h2>

          <hr className="mx-auto mb-4" style={{ borderTopWidth: 3, borderTopColor: '#c7b48c', width: 200, opacity: 1 }} />

          <p className="text-center h3 fw-normal">
            <b className="text-primary h2 fw-bold">120,000</b> บาท ตลอดหลักสูตร<br />
          </p>
          <p className="text-center mb-4">(128,400 บาท รวม VAT)</p>

          <p className="h4 fw-normal text-center">
            รวม 1 Trip พัทยา และกิจกรรม Overnight Trade เรียบร้อยแล้ว
          </p>

          <h2 className="h1 fw-bold mt-5 pt-5 mb-3 text-white text-center">
            ช่องทางติดตามหลักสูตร
          </h2>

          <hr className="mx-auto mb-4" style={{ borderTopWidth: 3, borderTopColor: '#c7b48c', width: 200, opacity: 1 }} />

          <div className="d-flex h4 fw-normal align-items-center justify-content-center">
            <img src="/facebook.png" alt="Bullmoon Facebook" width="45" />
            : <a href="https://www.facebook.com/bullmoonexclusive" target="_blank" rel="noreferrer" className="text-white text-decoration-none ml-3">Bullmoon Exclusive</a>
          </div>

          <div className="d-flex h4 fw-normal align-items-center justify-content-center">
            <img src="/instagram.png" alt="Bullmoon Instagram" width="45" />
            : <a href="https://www.instagram.com/bullmoon.exclusive" target="_blank" rel="noreferrer" className="text-white text-decoration-none ml-3">Bullmoon Exclusive</a>
          </div>

          <p className="h4 fw-normal text-center mt-5">
            สอบถามรายละเอียดหรือขอความช่วยเหลือ ได้ที่
          </p>

          <div className="d-flex align-items-center justify-content-center flex-wrap h4 fw-normal">
            <div className="d-inline-flex align-items-center mx-3">
              <img src="/line.png" alt="Bullmoon Line" width="45" />
              <a href="https://lin.ee/8SkbU2x" target="_blank" rel="noreferrer" className="text-white text-decoration-none">@STOCK2MORROW</a>
            </div>
            <div className="d-inline-flex align-items-center mx-3">
              <img src="/call.png" alt="Bullmoon Contact" width="45" />
              09 0980 2196
            </div>
          </div>
        </div>
      </section>

      <Sticky mode="bottom" stickyStyle={{ zIndex: 10 }}>
        <div className="bg-white border-top text-end" style={{ paddingTop: 19, paddingBottom: 19 }}>
          <div className="container text-center">
            <a className="btn btn-primary btn-register rounded-pill d-inline-flex" href="https://docs.google.com/forms/d/e/1FAIpQLSdjSx4tFAy5r_CPmaYAeMnIt6ghmTA9e4_h2j7J6BhEdVFznw/viewform?usp=sf_link" target="_blank" role="button">
              สมัครหลักสูตร
            </a>
          </div>
        </div>
      </Sticky>
    </div>
  )  
}

export default CoursePage;