import "./Mazk_Banner3.css";
import * as THREE from 'three';
import React, { Component } from "react"; 
import { useState, useEffect } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';

//import { DebugEnvironment } from 'three/examples/jsm/environments/DebugEnvironment.js';
//import { ColorSpaceNode, MeshStandardNodeMaterial } from 'three/examples/jsm/nodes/Nodes.js';

var w , h;
var renderer = new THREE.WebGLRenderer({alpha:true, antialias:true});
var camera;
var clock = new THREE.Clock();
var mixer1;
var obj1;
var previousWidth;

var acdx = 0 , acdy = 0;
var vnmu = true;
var bw , bl, tttt;
var divw1;
var btt;

var fng1 = "test1_6.glb"
var fr = 1;
var offY = 0;
var zw = 50;
var jios = 0;
var scene = new THREE.Scene();
var fov1 = 22;
var topStory1 = 0;
var myRFA;
var loader;
var h1=0;


class Banner1 extends Component {

  componentDidMount() {
    
  }

  componentWillUnmount() {
  }
  
  render() {
    return (
      <div id='bullmoon_banner3_container'>
        <div style={{width:"100%", alignContent:"center", alignItems:"center", justifyContent:"center", display:'flex', flexWrap:'wrap', height:'auto'}} >
              <img style={{flex:'1',minWidth:'50%', width:'50%',height:'auto'}} src="p1.jpg" />
              <img style={{flex:'1',minWidth:'50%',width:'50%',height:'auto'}} src="p2.jpg" /><br/><br/>
              <img style={{flex:'1',minWidth:'300px',width:'50%',height:'auto'}} src="p3.jpg" />
              <img style={{flex:'1',minWidth:'300px',width:'50%',height:'auto'}} src="p4.jpg" /><br/><br/>
        </div>
        {/************************************************/}
        <div id='bullmoon_banner3_maindiv'>
          {/************************************************/}
          <div id='bullmoon_banner3_div2'>
            <center>
              <div id='bullmoon_banner3_div2_inner1'>
                <center>
                <table id="banner3_table">
                  <tbody>
                    </tbody>
                </table>
               {/* <hr style={{width:}}/> */}
              </center>
            </div>
            </center>
          </div>
          {/************************************************/}
        </div>
      </div>
    )
  }
}
export default Banner1;
