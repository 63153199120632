import {BrowserRouter as Router, Routes, Route , Navigate } from "react-router-dom"; 
import MazkMainPage from "./Main_Page/Mazk_MainPage";
import Mint_Presale from "./Mint_Page/Mint_Presale";
import Help from "./HelpPage";
import Exclusive002 from "./Exclusive002";
import CoursePage from "./Course";
import Course2Page from "./Course/course2";

export default function App() {
  return (
    <div  style={{width:"100%",height:"100%"  }} >
      <Router>
        <Routes>
          <Route path='/' element={ <MazkMainPage /> } />
          <Route path='/mintpub' element={ <Mint_Presale /> } />
          <Route path='/course-old' element={ <Exclusive002 /> } />
          <Route path='/course1' element={ <CoursePage /> } />
          <Route path='/course' element={ <Course2Page /> } />
          <Route path='/help' element={ <Help /> } />
          <Route path="*" element={<Navigate to="/" /> } />
        </Routes>
      </Router>
    </div>
  );
}
