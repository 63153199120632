import "./Mazk_FAQ.css";
import React, { Component } from "react";

class FAQ extends Component {
    componentDidMount() {
        //var FAQ_deveda = document.getElementById("FAQ_metaDevada");
        //var FAQ_main_table =  document.getElementById("FAQ_main_table")
        /*function FAQ_resize() {
            if(window.innerWidth >= 1000){
                FAQ_deveda.style.top = FAQ_main_table.offsetTop + 20 + "px";
                FAQ_deveda.style.width = 'min(370px,23vw)';
                FAQ_deveda.style.left = FAQ_main_table.offsetLeft + FAQ_main_table.offsetWidth - FAQ_deveda.width + 40+ "px";
            }
        }*/
        //FAQ_resize();
        //window.addEventListener('resize',FAQ_resize);

        /*function FAQ_deveda_rotate(){
            if(window.innerWidth >= 1000){
                var ang = (window.scrollY - FAQ_main_table.scrollTop) /105 - 40 ;
                FAQ_deveda.style.top = FAQ_main_table.offsetTop - ang*20 - 220 + "px";
            }
        }*/
        //window.addEventListener('scroll',FAQ_deveda_rotate);
        for(let iii=1;iii<=4;iii++){
            document.getElementById("faq_q_"+iii).onclick = function(){ showAns("aaa"+iii, iii ) } ;
        }
    }
    
    render() {
        return (
            <div >
                <div id='FAQ_main_container'>
                <table id='FAQ_main_table'>
                    <tbody>
                    <tr>
                        <td className='FAQ_main_table_header' id='FAQ_main_table_header'>
                            {/* <img src="FAQ_questionMark.png" className="FAQ_questionMark2" alt="" /> */}
                            FAQ
                            {/* <img src="FAQ_questionMark.png" className="FAQ_questionMark1" alt="" /> */}
                        </td>
                    </tr>
                    <tr>
                        <td className='FAQ_main_table_td_question' id='faq_q_1' >
                        <img id='faq_up_btn_1' src="down_arrow_1.png" style={{display:'inline',width:'22px'}} alt="" /> {this.props.lang != "en" ? "BullMoonClub NFT อยู่บน Blockchain ของอะไร?" : "What ‘s BullMoonClub NFT blockchain?"}
                        </td>
                    </tr>
                    <tr>
                        <td className='FAQ_main_table_td_answer' id='aaa1'>
                        {   this.props.lang != "en" ?
                            <div>อยู่บน Ethereum (ETH) blockchain <br className="FAQ_br" />ในระบบ Smart Contract</div> : 
                            "Ethereum(ETH) blockchain with Smart contract"
                        }
                        </td>
                    </tr>
                    <tr>
                        <td className='FAQ_main_table_td_question'  id='faq_q_2' >
                        <img id='faq_up_btn_2' src="down_arrow_1.png" style={{display:'inline',width:'22px'}} alt="" />
                        {   this.props.lang != "en" ?
                         " BullMoonClub NFT ขายเมื่อไหร่" :
                         " When is the first Mint?"
                        }
                        </td>
                    </tr>
                    <tr>
                        <td className='FAQ_main_table_td_answer' id='aaa2'>
                            { this.props.lang != "en" ?
                                "เปิดขาย 25 ก.ค." :
                                "July 25, 2022."
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className='FAQ_main_table_td_question'  id='faq_q_3' >
                        <img id='faq_up_btn_3' src="down_arrow_1.png" style={{display:'inline',width:'22px'}} alt="" /> 
                        {   this.props.lang != "en" ?
                            " จะซื้อ BullMoonClub NFT ต้องทำอย่างไร?" :
                            " How can you mint BullMoonClub NFT?"
                        }
                        </td>
                    </tr>
                    <tr>
                        <td className='FAQ_main_table_td_answer' id='aaa3' style={{textAlign:'left',lineHeight:'0px'}}>
                        {   this.props.lang != "en" ?
                            <div >
                                1. เปิดบัญชีกับทาง Exchange เช่น Binance, Bitkub, Bitazza, Upbit, etc. เพื่อซื้อเหรียญ ETH (Ethereum)
                                <br/>2. เปิดกระเป๋า (Wallet) ที่ MetaMask เพื่อเก็บ NFT
                                <br/>3. โอนเหรียญ ETH เข้า MetaMask
                                <br/>4. เข้าเว็บ bullmoon.stock2morrow.com และทำการเชื่อมต่อกับกระเป๋า MetaMask
                                <br/>5. กด Mint NFT ในวันที่ 25 ก.ค. โดยใช้เหรียญ ETH
                            </div> :
                            <div>
                                1.Create your account with Exchange channels such as Binance,Bitazza,Upbit,etc. to purchase ETH (Ethereum).
                                <br/>2.Open your wallet via MastaMask to collect NFT.
                                <br/>3.Transfer ETH into MetaMask.
                                <br/>4.Go to bullmoon.stock2morrow.com and connect your wallet with MetaMask.
                                <br/>5.Press Mint NFT on Mint Date by purchasing ETH or contact Bullmoon Team to help you mint !
                            </div>
                        }
                        </td>
                    </tr>

                    <tr>
                        <td className='FAQ_main_table_td_question'  id='faq_q_4' >
                        <img id='faq_up_btn_4' src="down_arrow_1.png" style={{display:'inline',width:'22px'}} alt="" /> 
                        { this.props.lang != "en" ? " BullMoonClub NFT มีจำนวนทั้งหมดกี่ชิ้น? กี่แบบ?" : " How many BullMoonClub NFT items ?" }
                        </td>
                    </tr>
                    <tr>
                        <td className='FAQ_main_table_td_answer' id='aaa4' style={{textAlign:'left',lineHeight:'0px'}}>
                        <center>
                        {   this.props.lang != "en" ? 
                            <div>มีทั้งหมด 5,500 ชิ้น แบ่งเป็น 3 ระดับ
                                <br/>1. ระดับ Common จำนวน 70%
                                <br/>2. ระดับ Rare จำนวน 29%
                                <br/>3. ระดับ SuperRare จำนวน 1%
                            </div> : 
                            <div>Total 5,500 items
                                <br/>1. Common 70%
                                <br/>2. Rare 29%
                                <br/>3. SuperRare 1%
                            </div>
                        }
                        </center>
                        </td>
                    </tr>
                    
                    <tr>
                        <td>
                            {/* <img src="FAQ_pop2.png" id="FAQ_metaDevada" alt="" /> */}
                        </td>
                    </tr>
                    <tr>
                        <td >
                            
                            <center>
                                <br/><hr style={{width:'40%'}}/><br/>
                                <div className='FAQ_main_table_td_social' >
                                    <div id="footericon_div">
                                        <a rel="noreferrer" href="https://www.facebook.com/Bullmoonclubnft" target={'_blank'} >
                                            <img  alt="" src="facebook.svg" className="FAQ_main_table_td_social_svg" />
                                        </a>
                                        <a rel="noreferrer" href="https://twitter.com/BullMoonClubNFT?s=20&t=HpkdqausEAp8PcnUiPXU-A"  target={'_blank'} >
                                            <img  alt="" src="twitter.svg" className="FAQ_main_table_td_social_svg" />
                                        </a>
                                        <a rel="noreferrer" href="https://discord.com/invite/Xe5Njdgar2"  target={'_blank'} >
                                            <img  alt="" src="discord.svg" className="FAQ_main_table_td_social_svg" />
                                        </a>
                                        <a rel="noreferrer" href="https://t.me/BullMoonClubNFT" target={'_blank'}  >
                                            <img  alt="" src="telegram.svg" className="FAQ_main_table_td_social_svg" />
                                        </a>
                                        <a rel="noreferrer" href="https://line.me/ti/g2/B5x_N2CdP6Uju9nSBdx1IbNVhjNGx_6ir7srCA?utm_source=invitation&utm_medium=link_copy&utm_campaign=default"  target={'_blank'} >
                                            <img  alt="" src="line.svg" className="FAQ_main_table_td_social_svg" />
                                        </a>
                                        
                                        <div id="footericon_div_forLandscape"> &nbsp;&nbsp;Copyright 2022 Stock2morrow | All rights reserved. <br/></div>
                                    </div>
                                </div>
                            </center>
                        </td>
                    </tr>
                    <tr>
                        <td className='FAQ_main_table_td_end'>
                            <center>
                            Copyright 2022 Stock2morrow | All rights reserved. <br/><br/><br/>
                            </center>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
        )
    }
}
export default FAQ;

function showAns(name,iii){
    var olppo = document.getElementById(name);
    var thisBTN = document.getElementById("faq_up_btn_"+iii);
    thisBTN.style.transform = 'rotate(-180deg)';
    if(window.innerWidth >= 1000){
        olppo.style.padding = '20px' ;
        olppo.style.fontSize = 'min(3.5vw,23px)' ;
        olppo.style.lineHeight= 'min(4.5vw,26px)' ;
    }else{
        olppo.style.padding = '1.5vw' ;
        olppo.style.fontSize = '3.1vw' ;
        olppo.style.lineHeight= '4.5vw' ;
        olppo.style.padding= '20px' ;
    }
    document.getElementById("faq_q_"+iii).onclick = function() { hideAns(name,iii); }
}

function hideAns(name,iii) {
    var olppo = document.getElementById(name);
    var thisBTN = document.getElementById("faq_up_btn_"+iii);
    olppo.style.padding = "0px";
    olppo.style.fontSize = '0px' ;
    olppo.style.lineHeight= '0vw' ;
    thisBTN.style.transform = 'rotate(0deg)';
    document.getElementById("faq_q_"+iii).onclick = function() { showAns(name,iii); }
}