const executives = [
  {
    name: "อัญพัชร์ ทรัพย์ยืนยง (ยุ้ย)",
    role: "ผู้เชี่ยวชาญด้านการลงทุนอสังหาฯ",
    image: "/speakers/ยุ้ย.png",
  },
  {
    name: "ประกาศิต ทิตาราม (ปุย)",
    role: "นักลงทุนและนักธุรกิจ<br/>CEO ทิตารามกรุ๊ป",
    image: "/speakers/ปุย.png",
  },
  {
    name: "วิชัย ทองแตง",
    role: "นักธุรกิจและนักลงทุน<br/>แถวหน้าของประเทศไทย",
    image: "/speakers/วิชัย.png",
  },
  {
    name: "ปิยพันธ์ วงศ์ยะรา (ป้อม)",
    role: "CEO<br/>2morrow group",
    image: "/speakers/ป้อม.png",
  },
  {
    name: "พรชัย บุญญาทานวงศ์ (หนุ่ม)",
    role: "NFT Collector & Crypto Specialist",
    image: "/speakers/หนุ่ม.png",
  },
];

const stockSpeakers = [
  {
    id: 1,
    name: "ดร.วิศิษฐ์ องค์พิพัฒนกุล",
    role: "กรรมการผู้จัดการ<br/>หลักทรัพย์ ทรีนีตี้",
    image: "/speakers/1-ดร.วิศิษฐ์.png",
  },
  {
    id: 2,
    name: "วัชระ แก้วสว่าง (เสี่ยป๋อง)",
    role: "นักลงทุนรายใหญ่ของตลาดหุ้นไทย",
    image: "/speakers/2-เสี่ยป๋อง.png",
  },
  {
    id: 3,
    name: "ดร.วีรพงษ์ ชุติภัทร์",
    role: "CEO<br/>Real Estate Exponential (RealX)",
    image: "/speakers/3-ดร.วีรพงษ์.png",
  },
  {
    id: 4,
    name: "โอฬาร ภัทรกอบกิตติ์ (บ๊อบ)",
    role: "แชมป์แฟนพันธ์แท้ตลาดหุ้นไทยคนแรก",
    image: "/speakers/4-บ๊อบ.png",
  },
  {
    id: 5,
    name: "อธิป กีรติพิชญ์ (นิ้วโป้ง)",
    role: "นักลงทุน เจ้าของเพจ นิ้วโป้ง Fundamental VI",
    image: "/speakers/5-นิ้วโป้ง.png",
  },
  {
    id: 6,
    name: "นิติพงษ์ หิรัญพงษ์ (ป๊อบ)",
    role: "Fulltime Trader เจ้าของเพจ โค้ชพี่ป๊อบ",
    image: "/speakers/6-ป๊อบ.png",
  },
  {
    id: 7,
    name: "ตราวุทธิ์ เหลืองสมบูรณ์ (เผ่า)",
    role: "CEO<br/>หลักทรัพย์จัดการกองทุน จิตตะ เวลธ์ จำกัด",
    image: "/speakers/7-เผ่า.png",
  },
  {
    id: 8,
    name: "อัครพงศ์ ขวงธนะชัย (แมน)",
    role: "Fulltime Trader เจ้าของเพจการลงทุน Stockmanday",
    image: "/speakers/8-แมน.png",
  },
  {
    id: 9,
    name: "ธนาเดช โสมบุตร (จักร)",
    role: "Fulltime Trader<br/>และผู้ร่วมก่อตั้งบริษัท Deeper Trade",
    image: "/speakers/9-จักร.png",
  },
  {
    id: 10,
    name: "ปุณยวีร์ จันทรขจร (เป๊ก)",
    role: "CIO บริษัทหลักทรัพย์ที่ปรึกษาการลงทุน<br/>ควอนตัม เว็ลธ์ จำกัด",
    image: "/speakers/10-เป๊ก.png",
  },
];

const realEstateSpeakers = [
  {
    id: 1,
    name: "เลิศมงคล วราเวณุชย์ (เลิศ)",
    role: "ประธานกรรมการ<br/>สมาคมอสังหาริมทรัพย์ นนทบุรี",
    image: "/speakers/11-อ.เลิศมงคล.png",
  },
  {
    id: 2,
    name: "พันธวัฒน์ อาจศิริมานะชัย (โค้ชเต๊ะ)",
    role: "นักลงทุนอสังหาริมทรัพย์ เซียนขายฝาก<br/>และนักพัฒนาอสังหาริมทรัพย์",
    image: "/speakers/12-โค้ชเต๊ะ.png",
  },
  {
    id: 3,
    name: "ศิรประภา รักษ์สุจริต (จิ๊ป)",
    role: "เจ้าของเพจอสังหาเรื่องจิ๊บๆ<br/>CEO Wealthiness Estate",
    image: "/speakers/13-จิ๊ป.png",
  },
];

const digitalAssetSpeakers = [
  {
    id: 1,
    name: "สถาพน พัฒนะคูหา (แบงค์)",
    role: "CEO<br/>Guardian GPT",
    image: "/speakers/14-แบงค์.png",
  },
  {
    id: 2,
    name: "อิสรียาห์ ประดับเวทย์ (พลอย)",
    role: "NFT Creator, NFT Project Consult<br/>COO Thailand Institute of Justice",
    image: "/speakers/15-พลอย.png",
  },
  {
    id: 3,
    name: "พงศ์พิศิชญ์ ประทีปะวณิช (เทอรี่)",
    role: "เจ้าของ เพจ iKnowTerry",
    image: "/speakers/16-เทอรี่.png",
  },
  {
    id: 4,
    name: "แพทย์หญิง นวพร นะลิตา",
    role: "Founder Crypto City Connext<br/>CEO TYVM CO.,LTD.",
    image: "/speakers/17-นวพร.png",
  },
];

const upskillSpeakers = [
  {
    id: 1,
    name: "พจน์ หะริณสุต",
    role: "CEO<br/>บลจ.วรรณ",
    image: "/speakers/18-พจน์.png",
  },
  {
    id: 2,
    name: "กฎทอง บุญพรรคนาวิก (ครูโอ๋)",
    role: "ที่ปรึกษาด้านการสื่อสารและบุคลิกภาพแบรนด์",
    image: "/speakers/19-ครูโอ๋.png",
  },
  {
    id: 3,
    name: "กัณฑ์ คลอวุฒินันท์",
    role: "นักลงทุน สาย Blockchain",
    image: "/speakers/20-กัณฑ์.png",
  },
];

const Speaker2List = () => {
  return (
    <>
      <h2 className="h2 fw-bold mt-5 pt-5 text-center">ผู้บริหารหลักสูตร</h2>
      <div className="row mt-4">
        {executives.map((executive, index) => {
          return (
            <div
              className={`col-sm-6 col-md-4 col-lg mb-4 
                ${index === 0 ? "mobile-reorder4" : ""} 
                ${index === 1 ? "mobile-reorder3" : ""}
                ${index === 2 ? "mobile-reorder1" : ""}
                ${index === 3 ? "mobile-reorder2" : ""}
                ${index === 4 ? "mobile-reorder5" : ""}
              `}
              key={index}
            >
              <div className="card shadow-none speaker-item h-100">
                <div className="card-body text-center">
                  <img
                    src={executive.image}
                    className="speaker-profile img-fluid mb-3 h-auto"
                    alt={executive.name}
                  />

                  <div className="flex-fill mt-1">
                    <div className="fw-semibold h5" style={{ fontSize: 16 }}>
                      {executive.name}
                    </div>
                    <p
                      className="text-muted f-12 mb-0"
                      dangerouslySetInnerHTML={{ __html: executive.role }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <h2 className="h1 fw-bold mt-5 text-center">วิทยากร รุ่น 2</h2>

      <h2 className="h2 fw-bold mt-5 text-center">Speaker : Bridge To STOCK</h2>

      <div className="row mt-4">
        {stockSpeakers.map((speaker, index) => {
          return (
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
              <div className="card shadow-none speaker-item h-100">
                <div className="card-body text-center">
                  <img
                    src={speaker.image}
                    className="speaker-profile img-fluid mb-3"
                    alt={speaker.name}
                  />

                  <div className="flex-fill mt-1">
                    <div className="fw-semibold h5" style={{ fontSize: 16 }}>
                      {speaker.name}
                    </div>
                    <p
                      className="text-muted f-12 mb-0"
                      dangerouslySetInnerHTML={{ __html: speaker.role }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <h2 className="h2 fw-bold mt-5 text-center">
        Speaker : Bridge To Real Estate
      </h2>

      <div className="row mt-4">
        {realEstateSpeakers.map((speaker, index) => {
          return (
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
              <div className="card shadow-none speaker-item h-100">
                <div className="card-body text-center">
                  <img
                    src={speaker.image}
                    className="speaker-profile img-fluid mb-3"
                    alt={speaker.name}
                  />
                  <div className="flex-fill mt-1">
                    <div className="fw-semibold h5" style={{ fontSize: 16 }}>
                      {speaker.name}
                    </div>
                    <p
                      className="text-muted f-12 mb-0"
                      dangerouslySetInnerHTML={{ __html: speaker.role }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <h2 className="h2 fw-bold mt-5 text-center">
        Speaker : Bridge To Digital Asset
      </h2>

      <div className="row mt-4">
        {digitalAssetSpeakers.map((speaker, index) => {
          return (
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
              <div className="card shadow-none speaker-item h-100">
                <div className="card-body text-center">
                  <img
                    src={speaker.image}
                    className="speaker-profile img-fluid mb-3"
                    alt={speaker.name}
                  />
                  <div className="flex-fill mt-1">
                    <div className="fw-semibold h5" style={{ fontSize: 16 }}>
                      {speaker.name}
                    </div>
                    <p
                      className="text-muted f-12 mb-0"
                      dangerouslySetInnerHTML={{ __html: speaker.role }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <h2 className="h2 fw-bold mt-5 text-center">
        Speaker : Bridge To Upskill
      </h2>

      <div className="row mt-4">
        {upskillSpeakers.map((speaker, index) => {
          return (
            <div className="col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
              <div className="card shadow-none speaker-item h-100">
                <div className="card-body text-center">
                  <img
                    src={speaker.image}
                    className="speaker-profile img-fluid mb-3"
                    alt={speaker.name}
                  />
                  <div className="flex-fill mt-1">
                    <div className="fw-semibold h5" style={{ fontSize: 16 }}>
                      {speaker.name}
                    </div>
                    <p
                      className="text-muted f-12 mb-0"
                      dangerouslySetInnerHTML={{ __html: speaker.role }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Speaker2List;
